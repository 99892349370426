import { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';

const AdCarousel = ({ data }) => {
    const [adData, setAdData] = useState([]);
    
    useEffect(() => {
        if (data) {
            setAdData(data.list);
        }
    }, [data]);

    return (
        <Carousel>
            {adData.map((item, index) => (
                <Carousel.Item key={index}>
                    <img 
                        src={item.advertiseInfo.adFileUrl} 
                        className="d-block w-100" 
                        style={{
                            height: '21.563rem',
                            borderRadius: '1.5rem',
                            objectFit: 'cover',
                        }} 
                        alt={`Slide ${index + 1}`} 
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default AdCarousel;
