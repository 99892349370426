

// import { altRadarWhiteIcon, facebookBlackIcon, instaBlackIcon, twitterBlackIcon } from '../../../assets/icons/index';
import altRadarWhiteIcon from '../../assets/images/svg/altRadar-logo-white.svg';
import facebookIcon from '../../assets/images/svg/footerFacebook.svg';
import instaIcon from '../../assets/images/svg/footerInstagram.svg';
import twitterIcon from '../../assets/images/svg/footerTwitter.svg';
import appleStore from '../../assets/images/svg/appleStoreFull.svg';
import playStore from '../../assets/images/svg/playStoreFull.svg';
import mobiles from '../../assets/images/svg/footer2.png';
import classes from './footer2.module.css';

const Footer2 = () => {
    return (
        <>
            <div className={classes.bottomSection}>
                <div className="container-fluid">
                    <div className="row">
                        <div className={`col-lg-4 col-md-4 col-sm-4 align-self-center ${classes.bottom}`}>
                            <p className={classes.bottomSectionHeading}>Always stay connected</p>
                            <p className={classes.bottomSectionHeading1}>Music on the go!</p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <img src={mobiles} alt="" className={classes.phoneImage} />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className={classes.appsLogo1}>
                                <img src={appleStore} alt="white-apple" className={classes.appStore} />
                                <img src={playStore} alt="White Play store" className={classes.playStore} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={classes.footerSection}>
                <div className="container-fluid">
                    <div className="row">
                        <div className={`${classes.logoSection} col-lg-3 col-md-3 col-sm-3 col-xs-3`}>
                            <div>
                                <div className={classes.logoContainer}>
                                    <a href="/" target="_self">
                                        <img src={altRadarWhiteIcon} alt="altRadar Logo" />
                                    </a>
                                </div>
                                <div className={classes.footerHeading4}>Get social with us</div>
                                <div className={classes.socialIcons}>
                                    <img src={facebookIcon} alt="facebook icon" />
                                    <img className="mx-3" src={twitterIcon} alt="twitter icon" />
                                    <img className="mx-1" src={instaIcon} alt="instagram icon" />
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.aboutSection} col-lg-3 col-md-3 col-sm-3 col-xs-3`}>
                            <h1 className={classes.aboutHeading}>About</h1>
                            <p className={classes.aboutParagraph}>About us</p>
                            <p className={classes.aboutParagraph}>Investors</p>
                            <p className={classes.aboutParagraph}>Press Release</p>
                            <p className={classes.aboutParagraph}>Privacy Policy</p>
                            <p className={classes.aboutParagraph}>Terms of Usage</p>
                            <p className={classes.aboutParagraph}>Legal</p>
                        </div>
                        <div className={`${classes.forSection} col-lg-3 col-md-3 col-sm-3 col-xs-3`}>
                            <h1 className={classes.aboutHeading}>For Artists</h1>
                            <p className={classes.aboutParagraph}>What's there for me</p>
                            <p className={classes.aboutParagraph}>Tips & Resources</p>
                            <p className={classes.aboutParagraph}>Jobs</p>
                            <p className={classes.aboutParagraph}>Premium Plans</p>
                            <p className={classes.aboutParagraph}>FAQs</p>
                            <p className={classes.aboutParagraph}>Contact us</p>
                        </div>
                        <div className={`${classes.For2Section} col-lg-3 col-md-3 col-sm-3 col-xs-3`}>
                            <h1 className={classes.aboutHeading}>For Listeners</h1>
                            <p className={classes.aboutParagraph}>What's there for me</p>
                            <p className={classes.aboutParagraph}>Tips & Resources</p>
                            <p className={classes.aboutParagraph}>Premium Plans</p>
                            <p className={classes.aboutParagraph}>Albums</p>
                            <p className={classes.aboutParagraph}>Live</p>
                        </div>
                    </div>
                    <h3 className={classes.footerBottomHeading}>Copyright altRadar © 2021 | All Rights Reserved</h3>
                </div>
            </div>
        </>


    )
}

export default Footer2;