import classes from './AdManager.module.css';
import PageThreeForm from '../../forms/AdManagerForm/pagethree';

const PageOne = ({ handleNextClick, form, setForm, vidPreview, setTotalDays, totalDays, customDays, setCustomDays }) => {
    return (
        <>
            <div className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}>
                <div className='col-6'>
                    <video src={vidPreview} autoPlay controls className='w-100' style={{ borderRadius: '8px' }} />
                </div>
                <div className='col-5 d-flex'>
                    <PageThreeForm
                        form={form}
                        setForm={setForm}
                        handleNextClick={handleNextClick}
                        setTotalDays={setTotalDays}
                        totalDays={totalDays}
                        customDays={customDays}
                        setCustomDays={setCustomDays}
                    />
                </div>
            </div>
        </>
    )
}

export default PageOne;