import classes from './AdDemographics.module.css';
import AdDemographicsVideo from './vid';
import AdDemographicsTabs from './tabs';
import AdDemographicsCards from './AdInfo/cards';
import GoBackComponent from '../../GoBack';
import AdAnalytics from './AdAnalytics';

import { useState } from 'react';

const Demographics = () => {
    // 0 = Ad Info
    // 1 = Ad Analytics
    const [activeTab, setActiveTab] = useState(1);
    const handleActiveTab = () => {
        setActiveTab((prev) => Number(!prev))
    }
    return (
        <>
            <div className='container'>
                <GoBackComponent />
            </div>
            <AdDemographicsVideo />
            <AdDemographicsTabs handleActiveTab={handleActiveTab} activeTab={activeTab} />

            <div className='container'>
                <span className={classes.cardTitle}>
                    {
                        activeTab ? 'Ad Information' : 'Ad Analytics'
                    }
                </span>
            </div>

            {
                activeTab ? (
                    <>
                        <AdDemographicsCards />
                    </>
                ) : (
                    <>
                        <AdAnalytics />
                    </>
                )
            }

        </>
    )
}

export default Demographics;