import { useEffect, useState } from 'react';
import { previousSearchIcon } from '../../utils/images';
import classes from './SearchHistoryList.module.css';

const SearchHistoryDropDown = ({ HistoryArray, handleSearch, ref_, setFocusedList, showRecentImage }) => {
    const [list, setList] = useState([]);
    useEffect(() => {
        setList(HistoryArray);
    }, [HistoryArray])

    const listDiv = (index, item) => {
        return (
            <div
                key={index}
                className={classes.innerDiv}
                onClick={() => { handleSearch({ target: { value: item } }, 'history'); }}
            >
                {showRecentImage && <img src={previousSearchIcon} alt="previous search" />}
                <p>{item}</p>
            </div>
        )
    }

    const jsx = list?.map((item, index) => {
        if (item === '') return null;
        else return (
            listDiv(index, item)
        )
    })

    return (
        <div
            className={classes.searchHistoryListDiv}
            ref={ref_}
            onMouseDown={() => setFocusedList(true)}
            onBlur={() => setFocusedList(false)}
        >
            {jsx.length > 0 ? jsx : listDiv(0, 'No Suggestions Found')}
        </div>
    )
}

export default SearchHistoryDropDown;
