import React from 'react';
import { Helmet } from 'react-helmet';

const CircleSpaceEmbed = () => {
  const spaceSlug = "connect-7651d8"; // Replace with your actual Circle space slug
  const circleSpaceURL = `https://backstage-pass-9a7a26.circle.so/c/${spaceSlug}?iframe=true`;

  return (
    <div>
      <Helmet>
        <title>Circle Space - {spaceSlug}</title>
        <meta name="description" content={`Join our community on Circle space: ${spaceSlug}`} />
      </Helmet>
      <iframe
        title='Circle Space - {spaceSlug}'
        style={{ border: '0', boxShadow: 'none', width: '100%', height: '80vh' }}
        src={circleSpaceURL}
        frameBorder="0"
        scrolling="yes"
        allow="autoplay; encrypted-media"
        allowFullScreen
        data-theme="dark"  // Enables the dark theme in the iframe
      />
    </div>
  );
};

export default CircleSpaceEmbed;
