import classes from './VideoCard.module.css';
import { useLocation } from 'react-router-dom';

const ActiveInactivePill = ({ status }) => {
    const location = useLocation();
    if (!location.pathname.includes('advertisement')) return null;
    else
        return (
            <div
                style={{
                    background: '#FFF',
                    borderRadius: '20px',
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1% 2%',
                    margin: '.5rem',
                    color: '#F74037',
                    textTransform: 'capitalize'
                }}>
                <p style={{ margin: '0', fontSize: '12px' }}>{
                    location.pathname.includes('advertisement') ? 'Active' : 'inactive'
                }
                </p>
            </div>
        )
}

export default ActiveInactivePill;