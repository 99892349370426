// import adRadarBanner from '../../assets/images/svg/RadarPage/adCover.svg';
// import classes from './Temporary.module.css';

// import { BitmovinPlayer } from "bitmovin-player-react";
// import "bitmovin-player-ui/dist/css/bitmovinplayer-ui.css";
// import { Fragment } from "react";

// const playerConfig = {
//     key: "<key>",
//     playback: {
//         muted: false,
//         autoplay: false,
//     },
// };

// const playerSource = {
//     hls: "https://streams.bitmovin.com/cpuppplsrjnnig8jge9g/manifest.m3u8",
// };


import Player from "../../components/videojs/index";
const Temporary = ({ videoUrl }) => {

    const data = {
        videoUrl: videoUrl.length ? videoUrl : 'https://storage.googleapis.com/altradar-dev-bucket/COSTARICA4K.mp4',
        videoId: '1',
        title: 'CostaRica',
        thumbnailUrl: '',
    }
    return (
        <>
            <Player
                videoUrl={data.videoUrl}
                videoId={data.videoId}
                thumbnailUrl={data.thumbnailUrl}
                title={data.title}
                showShareButton={false}
                showAddWatchLaterButton={false}
                showNextButton={false}
            />
        </>
    );
}

export default Temporary;
