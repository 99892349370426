import { youtubeSearchDataList } from "../serverApi";
// import CustomToast from "../../../../utils/CustomToast";

const YoutubeSearchDataListAPI = async (query) => {
    try {
        const payload = {
            searchQuery: query.searchQuery,
            limit: query.limit,
            nextPageToken: query.nextPageToken
        }
        const result = await youtubeSearchDataList(payload);
        return result;
    } catch (error) {
        // CustomToast(error.message, 'error');
        console.log('error', error);

    }
}

export default YoutubeSearchDataListAPI;