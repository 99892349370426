import { createTransform } from 'redux-persist';
const CryptoJS = require('crypto-js');

const secureRandomKey = 'd5552d588d8c5b088f033296fffb7314';

// Custom encryption transform
const encryptionTransform = createTransform(
    // Transform state on its way to being serialized and stored
    (inboundState, key) => {
        const jsonString = JSON.stringify(inboundState);
        const encryptedData = CryptoJS.AES.encrypt(
            jsonString,
            secureRandomKey,
        ).toString();
        return encryptedData;
    },
    // Transform state being rehydrated
    (outboundState, key) => {
        const bytes = CryptoJS.AES.decrypt(outboundState, secureRandomKey);
        // console.log('bytes :====== ', bytes);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log('decryptedData :====== ', decryptedData);

        return decryptedData;
    },
);

export default encryptionTransform;