// modal with min width/height, centre align
import { useEffect } from 'react';
import classes from './Modal.module.css';
import { useState } from 'react';

export const Modal = ({ shouldShow, onRequestClose, w, wMin, children, hover, shouldClose, customClass }) => {
    const [showModal, setShowModal] = useState(false);
    const style = w ? { maxWidth: w } : null
    const style2 = wMin ? { minWidth: wMin } : null
    const close = () => {
        setShowModal(false);
    }


    useEffect(() => {
        if (shouldShow === true) {
            setShowModal(true);
        }
    }, [shouldShow])

    return showModal ? (
        <div
            className={`${classes.customModal} ${customClass && `${classes[customClass]}`} ${hover === true ? `${classes.hoverModal}` : ''} fixed flex items-center justify-center z-[1] h-full bg-black/40 overflow-auto`}
            style={style || style2}
        // onClick={onRequestClose}
        >
            <div
                className="w-3/4 bg-slate-300 rounded-lg"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {shouldClose === true && (
                    <div className="text-xl" style={{ cursor: 'pointer', float: 'right', position: 'absolute', top: '15px', right: '30px' }}
                        onClick={() => { close(); onRequestClose(); }}
                    >
                        X
                    </div>
                )}
                {/* <button className="text-xl"
                    onClick={setShowModal(false)}>
                    X
                </button> */}
                {children}
            </div>
        </div >
    ) : null;
};

export default Modal