import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import profileImage from '../../assets/images/svg/RadarPage/profileImg.svg';
import playIcon from '../../assets/images/svg/RadarPage/play.svg';
import liveRadio from '../../assets/images/svg/RadarPage/liveRadio.svg';
import verified from '../../assets/images/svg/RadarPage/verified.svg';
import liked from '../../assets/images/svg/RadarPage/liked.svg';
import disliked from '../../assets/images/svg/RadarPage/disliked.svg';
import classes from './VideoCarousel.module.css';
import Button from './Buttons';
// import moment from 'moment';
// import Modal from './Modal';
// import VideoModal from '../Landing/videoModal';
import Player from '../videojs/index';
import { momentFromNow } from '../../helpers/functions';
import SeeMoreOptionUI from './SeeMoreOption';
import BootstrapModal from './Bootstrap/modal';

/*
data: list of videos
img: image url or images
title: title of the carousel, if trivia need not to pass the value
type: type of the parent, ex: 'trivia' for trivia, leave it empty if it is not trivia
participate: boolean
variables: GraphQL query for video list at video-page
*/
const VideoCarousel = ({ data, img, title, type, participate, videoQuery, videoQueryVariables, videoQueryType }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pathName, setPathName] = useState('');
    const [videoData, setVideoData] = useState(null);
    const [videoModal, setVideoModal] = useState(false);
    const [videoModalData, setVideoModalData] = useState({});
    const [startTimer, setStartTimer] = useState(false);
    useEffect(() => {
        if (data) {
            setVideoData(data.list);
        }
    }, [data])
    useEffect(() => {
        setPathName(location.pathname);
    }, [location])

    // Close modal after 15 seconds for landing page.
    // useEffect(() => {
    //     if (startTimer) {
    //         setTimeout(() => {
    //             setVideoModal(false);
    //             navigate('/signIn', { state: { key: 'signin' } });
    //         }, 20000)
    //     }
    // }, [navigate, startTimer])

    // const [openVideo, setOpenVideo] = useState(false);
    // const [videoUrl, setVideoUrl] = useState('');
    // const widthCards = title === 'swipe up' ? '' : '26vw';
    const heightImage = title === 'swipe up' ? '' : '14vw';
    const widthImage = title === 'swipe up' ? '15vw' : '25vw';

    const boxRef = useRef(null);
    const [width, setWidth] = useState(0);
    // const itemsToShow = type === 'trivia' ? 3 : 10;

    const btnpressprev = () => {
        if (boxRef.current) {
            const box = boxRef.current;
            box.scrollLeft = box.scrollLeft - width;
        }
    }

    const btnpressnext = () => {
        if (boxRef.current) {
            const box = boxRef.current;
            box.scrollLeft = box.scrollLeft + width;
        }
    }

    // Update width when component mounts
    React.useEffect(() => {
        if (boxRef.current) {
            const box = boxRef.current;
            setWidth(box.clientWidth);
        }
    }, []);

    const contentList = videoData?.map((item, index) => {
        return (
            <div className='row mx-1' style={{
                borderRadius: '21px',
                border: '1px solid #707070',
                background: '#121215',
                maxWidth: '32%',
                flexDirection: 'column',
            }} key={index}>
                {/* thumbnail */}
                <div className='col p-0 m-0' style={{ position: 'relative', display: 'inline-block' }}>
                    <img className='img-responsive'
                        style={{
                            width: widthImage,
                            height: heightImage,
                            objectFit: 'cover',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                        }}
                        src={item.videoInfo.thumbnail} alt="" />
                    {title === 'live streaming' && (
                        <div
                            style={{
                                background: '#FF0000',
                                borderRadius: '5px',
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '2px 5px',
                                margin: '.5rem'
                            }}>
                            <img src={liveRadio} alt="" style={{ marginRight: '5px' }} />
                            <p style={{ margin: '0', fontSize: '12px' }}>Live</p>
                        </div>
                    )}
                    {
                        title === 'trending now' && (
                            <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', padding: '2px 5px', margin: '1.5rem', top: '0', bottom: '0', left: '0rem' }}>
                                <p style={{ margin: '0', fontSize: '7vw', color: '#FFFFFF' }}>{index + 1}</p>
                            </div>
                        )
                    }
                    {
                        title !== 'swipe up' && type !== 'trivia' && (
                            <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '15%', cursor: 'pointer' }} src={playIcon} alt=""
                                onClick={() => {
                                    if (pathName === '/') {
                                        setVideoModalData({
                                            videoUrl: item.videoInfo.videoUrl,
                                            videoId: item.videoInfo._id,
                                            thumbnailUrl: item.videoInfo.thumbnail,
                                            title: item.videoInfo.name,
                                        })
                                        setVideoModal(true);
                                        setStartTimer(true);
                                    }
                                    else if (!title.includes('live streaming'))
                                        navigate('/video-player', {
                                            state: {
                                                videoData: item.videoInfo,
                                                creatorData: item.creatorInfo,
                                                videoQuery: videoQuery,
                                                videoQueryVariables: videoQueryVariables,
                                                videoQueryType: videoQueryType
                                            }
                                        })
                                }} />

                        )
                    }
                    {
                        type === 'trivia' && (
                            <>
                                <Button label={'Play'} className={'trivia'}></Button>
                            </>
                        )
                    }
                </div>

                {/* text content */}
                <div className='col p-0 m-0'>
                    {title === 'swipe up' ? (
                        <>
                            <div style={{
                                padding: '4%',
                            }}>
                                <div className={`card-text ${classes.ellipsis}`} style={{ fontSize: '16px', lineHeight: '1.2', maxHeight: '2.4em' }}>
                                    Best English Songs 2023 -
                                    Billboard Hot 100 This Week...
                                </div>
                                <div className='d-flex justify-content-around' style={{
                                    marginBottom: '5px'
                                }}>
                                    <div>
                                        <img src={liked} alt="" />
                                        <span className='ps-1 pe-1' style={{ color: '#449D00', fontSize: '0.833vw' }}>Liked 2.3M</span>
                                    </div>
                                    <div>
                                        <img src={disliked} alt="" />
                                        <span className='ps-1' style={{ color: '#F74037', fontSize: '0.833vw' }}>Dislike 2.3M</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : <>
                        {type !== 'trivia' && (
                            <>
                                <div style={{
                                    padding: '4%',
                                    width: '100%',
                                    backgroundColor: '#121215',
                                    borderBottomLeftRadius: '22px',
                                    borderBottomRightRadius: '22px',
                                    display: 'grid',
                                    gridTemplateRows: '2.5rem auto auto auto',
                                    gap: '.2rem',
                                }}>
                                    <p className={`m-0 p-0 card-text ${classes.ellipsis} ${classes.cardText}`}>
                                        {item.videoInfo.name}
                                    </p>
                                    <div className='d-flex align-items-center' style={{
                                        color: '#707070',
                                        gap: '3%'
                                    }} >
                                        <div className={classes.circle}></div>
                                        <p className={`m-0 p-0 ${classes.watching}`}>{item?.videoInfo?.viewsCount ? item?.videoInfo?.viewsCount + ' views' : '1.5M Watching' || '1.5M Watching'}</p>
                                        <div className={classes.circle}></div>
                                        <p className={`m-0 p-0 ${classes.watching}`}>{item?.videoInfo?.createdAt ? momentFromNow(item?.videoInfo?.createdAt) : '3 months ago'}</p>
                                    </div>
                                    <div className='d-flex align-items-center' style={{
                                        marginBottom: '5px',
                                        gap: '.5rem'
                                    }}>
                                        <img style={{ width: '2.222vw', height: '2.222vw', borderRadius: '50%' }} src={item.creatorInfo?.profilePicture || profileImage} alt="" />
                                        <span className={`${classes.usernameText}`} style={{ color: '#6C6E72', fontFamily: 'Arial', fontWeight: '400' }}>{item.creatorInfo?.name || item.creatorInfo?.username || 'John Doe'}</span>
                                        <img src={verified} height={'15vw'} width={'15vh'} alt="" />
                                    </div>
                                    {/* Video's Description */}
                                    <p className={`m-0 p-0 ${classes.ellipsisOne} ${classes.description}`} style={{ color: '#6C6E72', fontWeight: '400', fontFamily: 'Arial' }}>
                                        {item.videoInfo.description ? item.videoInfo.description : 'Thank you for watching our livestream, please help me share this livestream with... '
                                        }
                                    </p>
                                </div>
                            </>
                        )}
                    </>}
                </div>
            </div >
        )
    })

    const handleSeeMore = () => {
        // console.log('title videos ui -- ', title.replaceAll(' ', '-'));

        navigate(`${title.replaceAll(' ', '-')}`);
    }

    return (
        <>
            <div className={classes.productCarousel}>
                <button className={classes.preBtn} onClick={btnpressprev}>
                    <span style={{ width: '50%', backgroundColor: '#fff', borderRadius: '50%', backgroundSize: '1rem', fontWeight: 'bold', color: '#000' }} aria-hidden="true">
                        <i className="fas fa-chevron-left"></i>
                    </span>
                </button>
                <button className={classes.nextBtn} onClick={btnpressnext}>
                    <span style={{ width: '50%', backgroundColor: '#fff', borderRadius: '50%', backgroundSize: '1rem', fontWeight: 'bold' }} aria-hidden="true">
                        <i className="fas fa-chevron-right"></i>
                    </span>
                </button>

                <div className='d-flex justify-content-between pb-2'>
                    <div className={classes.title}>{title}</div>
                    {participate === true && (
                        <Button label={'participate'} className={'active signin'} />
                    )}
                </div>

                <div className={classes.customList}
                    style={{
                        justifyContent: contentList?.length < 3 ? 'center' : 'flex-start',
                        // justifyContent: itemsToShow === 3 ? 'space-evenly' : ''
                    }}
                    ref={boxRef}>
                    {contentList}
                </div>
                <SeeMoreOptionUI handleClick={() => { handleSeeMore() }} />

                {/* <div className='m-2' style={{ float: 'right' }} onClick={handleSeeMore}>See more <i className="fas fa-arrow-right"></i></div> */}
            </div>

            {/* ---------Modal for landing page--------- */}
            <BootstrapModal
                size={'lg'}
                show={videoModal}
                className={'black-modal-photo'}
                showHeader={true}
                handleClose={() => { setVideoModal(false) }}
                body={
                    <>
                        <Player
                            videoUrl={videoModalData.videoUrl}
                            videoId={videoModalData.videoId}
                            thumbnailUrl={videoModalData.thumbnailUrl}
                            title={videoModalData.title}
                            path={pathName}
                        />
                    </>
                }
            />
        </>
    );
}

export default VideoCarousel;