import classes from './VideoCard.module.css';
import { deleteIcon, editIcon } from '../../../utils/images';
import { useState } from 'react';
import BootstrapModal from '../Bootstrap/modal';
import DeleteVidPromptComponent from '../../Model/DeleteVideoPrompt';
const { useNavigate, useLocation } = require("react-router-dom");

const Options = ({ handleVidDelete, videoData }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [deleteVid, setDeleteVid] = useState(false);

    const handleEdit = () => {
        // toast('Edit button clicked', { theme: 'dark' });
        if ((location.pathname).includes('my-videos')) {
            navigate(`/edit-video/${videoData.videoInfo._id}`, { state: videoData });
        }
        // handle advertisement edit below

    }

    const handleDelete = () => {
        setDeleteVid(true);
    }

    const handleClose = () => {
        setDeleteVid(false);
    }

    const handleSubmit = () => {
        setDeleteVid(false);
        handleVidDelete();
    }

    return (
        <>
            {deleteVid && <BootstrapModal
                show={deleteVid}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                body={<DeleteVidPromptComponent handleCancel={handleClose} handleDelete={handleSubmit} videoName={'videoName by Props'} />}
                className={'black-modal'}
            />}

            <div className={`col-2 d-flex align-items-center justify-content-end gap-1 ${classes.optionsDiv}`}>
                {
                    (location.pathname.includes('advertisement') || location.pathname.includes('my-videos')) &&
                    <span onClick={handleEdit}>
                        <img src={editIcon} alt="edit" />
                        {/* {editIcon()} */}
                    </span>
                }

                {
                    (location.pathname.includes('advertisement') || location.pathname.includes('my-videos')) &&
                    <span onClick={handleDelete}>
                        <img src={deleteIcon} alt="edit" />
                        {/* {deleteIcon()} */}
                    </span>
                }
            </div>

        </>
    )
}

export default Options;