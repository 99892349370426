import axios from 'axios';

const AppleFeedSongsSearch = async (name) => {
    try {
        // Make the GET request to the specified API endpoint
        const response = await axios.get(`https://altradaramfpf.ddns.net/data?name_default=${name}`, {
        });

        // Assuming you want to return or use the response data
        const genres = response.data;
        return genres;
    } catch (error) {
        // Log the error and show a toast or alert if necessary
        console.log('Error fetching genres:', error.message);
        // CustomToast(error.message, 'error'); // Uncomment if you have a toast function for UI feedback
    }
}

export default AppleFeedSongsSearch;
