import { useEffect, useState } from 'react';
import { getAllGenre } from '../services/requests/httpRequest/serverApi';

const useGetGenre = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAllGenre();
                setData(response.data);
            } catch (error) {
                console.error("Error fetching genre data:", error);
                setData([]);
            }
        };

        fetchData();
    }, []);

    return data;
};

export default useGetGenre;
