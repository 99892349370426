import { adCalculations } from "../../../../services/requests/httpRequest/serverApi";

export const GetCalculations = async (adCategory, amount, totalDays) => {
    try {
        const data = {
            "adCategory": adCategory,
            "amount": amount,
            "totalDays": totalDays
        }
        const result = await adCalculations(data);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }

}

export default GetCalculations;
