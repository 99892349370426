import classes from '../AdDemographics.module.css';
import { useState } from 'react';

import {
    adDemoCategory,
    adDemoCall2Action,
    adDemoLocation,
    adDemoBudget,
    adDemoGenre,
    adDemoAgeGroup,
    adDemoCalendar,
    adDemoGender,

    adDemoCategoryBg,
    adDemoCall2ActionBg,
    adDemoLocationBg,
    adDemoBudgetBg,
    adDemoGenreBg,
    adDemoAgeGroupBg,
    adDemoCalendarBg,
    adDemoGenderBg
} from '../../../../utils/images';

const AdDemographicsCards = () => {
    const defItems = [
        {
            image: adDemoCategory,
            title: 'Ad Category',
            bgImage: adDemoCategoryBg,
        },
        {
            image: adDemoCall2Action,
            title: 'Call to action',
            bgImage: adDemoCall2ActionBg,
        },
        {
            image: adDemoLocation,
            title: 'Locations',
            bgImage: adDemoLocationBg,
        },
        {
            image: adDemoBudget,
            title: 'Budget',
            bgImage: adDemoBudgetBg,
        },
        {
            image: adDemoGenre,
            title: 'Genre',
            bgImage: adDemoGenreBg,
        },
        {
            image: adDemoGender,
            title: 'Gender',
            bgImage: adDemoGenderBg,
        },
        {
            image: adDemoAgeGroup,
            title: 'Age groups',
            bgImage: adDemoAgeGroupBg,
        },
        {
            image: adDemoCalendar,
            title: 'Date of Post ',
            bgImage: adDemoCalendarBg,
        },
        {
            image: adDemoCalendar,
            title: 'End Date',
            bgImage: adDemoCalendarBg,
        },
    ]
    // const [items, setItems] = useState(defItems);

    return (
        <>
            <div className='d-flex flex-wrap col-12 m-2 justify-content-center'>
                {
                    defItems.map((item, index) => (
                        <div key={index} className={`${classes.card} m-2`}>
                            <img width={'20%'} height={'20%'} src={item.image} alt="card" />
                            <p className={classes.cardsTitle}>{item.title}</p>
                            <p className={classes.cardTitleData}>{`Dynamic Data for card`}</p>
                            <img className={classes.bgCardImg} src={item.bgImage} alt="cardbg" />
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default AdDemographicsCards;