import { useEffect, useState } from 'react';
import YoutubeSearchDataListAPI from '../../services/requests/httpRequest/POC/youtubeDataSearchListAPI';
import { useLocation } from 'react-router-dom';
import { playIcon } from '../../utils/images';
import classes from '../../components/ui/videoCard/VideoCard.module.css';
import { momentFromNow, nFormatter } from '../../helpers/functions';
import TemporaryPage from '../Temporary';
import GoBackComponent from '../../components/GoBack';

const YoutubeDataSearchPoc = () => {
    const Location = useLocation();
    const [searchParam, setSearchParam] = useState('');
    const limit = 9;
    const [nextPageToken, setNextPageToken] = useState(null);
    const [utubeDataList, setUtubeDataList] = useState([]);
    const [playVideoUrl, setPlayVideoUrl] = useState(null);
    const [exhausted, setExhausted] = useState(false);

    useEffect(() => {
        if (Location?.search) {
            // Extract the 'search' query parameter value
            const params = new URLSearchParams(Location.search).get('search');

            if (params) {
                const decodedParams = decodeURIComponent(params).replaceAll('+', ' '); // Decode and clean up the parameter
                setSearchParam(decodedParams); // Set the search parameter state
                fetchData(decodedParams); // Call fetchData with the correct parameter
            }
        }
    }, [Location?.search]);

    const fetchData = async (params) => {

        const payload = {
            searchQuery: params || searchParam,
            limit: limit,
            nextPageToken: nextPageToken
        }

        const result = await YoutubeSearchDataListAPI(payload);
        setUtubeDataList(result.data.list);
        setNextPageToken(result.data.nextPageToken);
        if (result.data.message) {
            setExhausted(true);
        } else {
            setExhausted(false);
        }
    }

    const handleSetVideoUrl = (id) => {
        const url = `https://www.youtube.com/watch?v=${id}`;
        setPlayVideoUrl(url);
    }

    return (
        <>
            <div style={{marginBottom: '45%'}}>
                <h4 className='d-flex gap-4'>{playVideoUrl !== null && <GoBackComponent handleBack={() => setPlayVideoUrl(null)} />} Youtube Data Search POC</h4>
                {
                    exhausted && (<><h6 className='text-center text-danger'>Youtube data api quota exhausted</h6></>)
                }
                {
                    playVideoUrl !== null ? (
                        <>
                            <TemporaryPage videoUrl={playVideoUrl} />
                        </>
                    ) : (
                        <>
                            <div className='d-flex flex-wrap justify-content-center' style={{ margin: '1%' }}>
                                {
                                    utubeDataList?.length > 0 && utubeDataList.map((data, index) => {
                                        return (
                                            <div className='row m-2' style={{
                                                borderRadius: '21px',
                                                border: '1px solid #707070',
                                                background: '#121215',
                                                maxWidth: '31%',
                                                flexDirection: 'column',
                                                margin: '1%',
                                            }} key={index}>
                                                {/* thumbnail */}
                                                <div className='col p-0 m-0' style={{ position: 'relative', display: 'inline-block' }}>
                                                    <img className='img-responsive w-100 h-100'
                                                        style={{
                                                            objectFit: 'cover',
                                                            borderTopLeftRadius: '20px',
                                                            borderTopRightRadius: '20px',
                                                        }}
                                                        src={
                                                            data?.snippet?.thumbnails?.high?.url
                                                        }
                                                        alt="" />
                                                    <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '15%', cursor: 'pointer' }} src={playIcon} alt=""
                                                        onClick={() => { handleSetVideoUrl(data?.id) }}
                                                    />
                                                </div>

                                                {/* text content */}
                                                <div className='p-0 m-0'>
                                                    <div style={{
                                                        padding: '4%',
                                                        width: '100%',
                                                        backgroundColor: '#121215',
                                                        borderBottomLeftRadius: '22px',
                                                        borderBottomRightRadius: '22px',
                                                        display: 'grid',
                                                        gridTemplateRows: '2.5rem auto auto auto',
                                                        gap: '.5rem',
                                                    }}>
                                                        <p className={`m-0 p-0 card-text ${classes.ellipsis} ${classes.cardText}`}>
                                                            {
                                                                data.snippet.title
                                                            }
                                                        </p>
                                                        <div className='d-flex align-items-center' style={{
                                                            color: '#707070',
                                                            gap: '3%'
                                                        }} >
                                                            <div className={classes.circle}></div>
                                                            <p className={`m-0 p-0 ${classes.watching}`}>{data?.statistics?.viewCount ? nFormatter(data?.statistics?.viewCount) + ' views' : '1.5M Watching' || '1.5M Watching'}</p>
                                                            <div className={classes.circle}></div>
                                                            <p className={`m-0 p-0 ${classes.watching}`}>{data?.snippet?.publishTime ? momentFromNow(data?.list.snippet?.publishTime) : '3 months ago'}</p>
                                                        </div>

                                                        {/* <div className='col d-flex'>
                                        <div className='col-10 d-flex align-items-center gap-1' >
                                            <img style={{ width: '2.222vw', height: '2.222vw', borderRadius: '50%' }} src={item.creatorInfo?.profilePicture || profileImage} alt="" />
                                            <span className={`${classes.usernameText}`} style={{ color: '#6C6E72', fontFamily: 'Arial', fontWeight: '400' }}>{item.creatorInfo?.name || item.creatorInfo?.username || 'John Doe'}</span>
                                            <img src={verified} height={'15vw'} width={'15vh'} alt="" />
                                        </div>
                                    </div> */}
                                                    </div>
                                                </div>
                                            </div >
                                        )
                                    })
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}

export default YoutubeDataSearchPoc;