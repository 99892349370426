import './progress.css';

const Progress = ({ currentPage }) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center text-white text-center steps-main my-5">
                <div className={`w-20-show justify-content-center ${currentPage > 1 ? "active" : ""}`}>
                    <div className="circle-steps align-items-center justify-content-center">1</div>
                </div>
                <div className={`w-20-show justify-content-center ${currentPage > 2 ? "active" : ""}`}>
                    <div className="circle-steps align-items-center justify-content-center">2</div>
                </div>

                <div className={`w-20-show justify-content-center ${currentPage > 3 ? "active" : ""}`}>
                    <div className="circle-steps align-items-center justify-content-center">3</div>
                </div>
                <div className={`w-20-show justify-content-center ${currentPage > 4 ? "active" : ""}`}>
                    <div className="circle-steps align-items-center justify-content-center">4</div>
                </div>
            </div>
        </>
    )
}

export default Progress