import { useState, useEffect } from 'react';
import { socket } from './index';
import { setUploadVideoSnapshots, setSnapshotId } from '../../store/redux/application/actions';
import { useDispatch } from 'react-redux';

const SocketIO = ({ stream }) => { // Accepting stream as a prop
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        // Handle thumbnail generated event
        socket.on('THUMBNAIL_GENERATED', (data) => {
            dispatch(setSnapshotId(data.FileId));
            dispatch(setUploadVideoSnapshots(data.uris)); // Handling snapshot generation
        });

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('THUMBNAIL_GENERATED', onDisconnect);
        };
    }, [dispatch]);

    if (isConnected) {
        console.log('-------- socket connected --------');
    }

    return null; // or return some UI if needed
};

export default SocketIO;
