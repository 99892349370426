import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useGetAccessToken } from "../store/redux/token/hooks";
import { useGetRole } from "../store/redux/application/hooks";
import ROUTES from "../constants/routes-role";
import { setClearToken } from "../store/redux/token/actions";
import { setClearApplication } from "../store/redux/application/actions";
import { setClearUser } from "../store/redux/user/actions";
import { useDispatch } from "react-redux";

const ProtectedRoutes = ({ children, ...rest }) => {
    const dispatch = useDispatch();
    const accessToken = useGetAccessToken();
    const Role = useGetRole();
    const location = useLocation();

    // Function to clear all tokens and state
    const clearAll = () => {
        dispatch(setClearToken());
        dispatch(setClearApplication());
        dispatch(setClearUser());
    };

    // console.log('ProtectedRoutes::', Role);

    // Check if the current route is authorized based on role and access token
    // console.log('ProtectedRoutes::', accessToken, Role);
    if (accessToken && Role) {
        for (const object of ROUTES) {
            if (location.pathname.split('/').includes(object.path) || location.pathname === object.path) {
                if (object.role.includes(Role)) {
                    if (accessToken && accessToken !== null && accessToken !== undefined) {
                        // If authorized, render the child components
                        return <Outlet />;
                    } else {
                        // If not authorized, clear state and navigate to the root path
                        clearAll();
                        return <Navigate to="/" replace={true} state={{ from: location }} />;
                    }
                } else {
                    return <Navigate to="/radar" replace={true} state={{ from: location }} />;
                }
            }
        }
    } else {
        // If no access token or role, clear state and navigate to the root path
        clearAll();
        return <Navigate to="/" replace={true} state={{ from: location, message: "Access Denied" }} />;
    }

    // If no route matches, navigate to the not-found page
    return <Navigate to="/not-found" replace={true} state={{ from: location }} />;
};

export default ProtectedRoutes;
