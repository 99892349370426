import { addAdvertisement } from "../serverApi";

export const NewAdvertisement = async (formData) => {
    try {
        const result = await addAdvertisement(formData);
        return result;
    } catch (error) {
        console.error("Error fetching data:", error);
    }

}

export default NewAdvertisement;
