import classes from './AdManager.module.css';
import searchIcon from '../../../assets/images/icons/magnifyingRed.svg';

const SearchBar = () => {
    return (
        <>
            <div className={`${classes.searchBarDiv}`}>
                <input
                    className={classes.searchInput}
                    style={{ backgroundImage: `url(${searchIcon})` }}
                    type="text" placeholder="Enter AD Title" />
            </div>
        </>
    )
}

export default SearchBar;