import classesAd from './AdManagerForm.module.css';
import classes from '../Form.module.css';
import RadioCustom from '../RadioCustom';
import { useState, useRef } from 'react';
import ImageIcon from '../../../assets/images/svg/personalizeProfile/uploadImg.svg';
import Button from '../../ui/Buttons';

const PageOne = ({ form, setForm, handleNextClick, setImgPreview, setVidPreview, imgPreview, videPreview }) => {
    const [uploadVideo, setUploadVideo] = useState(false);
    const options = ['in between video ads', '3 Mins. video'];
    const videoRef = useRef(null);
    const thumbnailRef = useRef(null);
    const videoDuration = useRef(0);
    const [videoPreview, setVideoPreview] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState('');
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [errorMsg, setErrorMsg] = useState({});

    const handleAdType = (option) => {
        setErrorMsg({
            fieldName: 'adType',
            msg: ''
        });
        setForm({ ...form, 'adCategory': option });
    }

    const handleUploadVideo = () => {
        setUploadVideo(true);
    }

    const handleInput = (value, key) => {
        const updatedForm = { ...form }
        updatedForm[key] = value;
        setForm({ ...updatedForm });
    }

    const handleFile = async (e, name) => {
        const uploadedFile = e?.currentTarget?.files?.[0];
        if (uploadedFile) {
            if (name === 'videoUrl') {
                let as_base64 = URL.createObjectURL(e.target.files[0])
                let media = new Audio(as_base64);
                media.onloadedmetadata = async () => {
                    if (media.duration < 5 || media.duration > 15) {
                        setErrorMsg({
                            fieldName: 'videoUrl',
                            msg: 'Video should be atleast 5 seconds long and should not exceed 15 seconds!'
                        });
                        return;
                    }
                    let as_files = e.target.files[0];
                    setVideoPreview(as_base64);
                    setVidPreview(as_base64); // for parent to share to children
                    setVideoFile(as_files);
                    videoDuration.current = media.duration;
                    setErrorMsg({
                        fieldName: 'videoUrl',
                        msg: ''
                    });
                    setForm({ ...form, 'adFileUrl': videoFile });
                }
            }
            else if (name === 'thumbnail') {
                let image_as_base64 = URL.createObjectURL(e.target.files[0])
                let image_as_files = e.target.files[0];
                setThumbnailPreview(image_as_base64);
                setImgPreview(image_as_base64); // for parent to share to children
                setThumbnailFile(image_as_files);
                setErrorMsg({
                    fieldName: 'thumbnail',
                    msg: ''
                });
                setForm({ ...form, 'thumbnailUrl': thumbnailFile });
            }
        }
    };

    const submitPage = () => {
        if (!form.adCategory) {
            setErrorMsg({
                fieldName: 'adCategory',
                msg: 'Please select an option'
            })
            return;
        }
        if (!form.adTitle) {
            setErrorMsg({
                fieldName: 'adTitle',
                msg: 'Please enter title'
            })
            return;
        }
        if (form.adFileUrl === '') {
            setErrorMsg({
                fieldName: 'adFileUrl',
                msg: 'Please upload fiile'
            })
        }
        if (form.thumbnailUrl === '') {
            setErrorMsg({
                fieldName: 'thumbnailUrl',
                msg: 'Please upload file'
            })
            return;
        }

        handleNextClick();
    }

    return (
        <>
            <div className='row gap-3 pb-4 mb-4'>
                {/* Ad type */}
                <div className='p-0'>
                    <span>What do you want to focus on*?</span>
                    <form action="
                    
                    ">
                        {
                            options.map((option, index) => {
                                return (
                                    <RadioCustom key={index} checked={form.adCategory === option} option={option} onSelect={() => { handleAdType(option) }} />
                                )
                            })
                        }
                    </form>
                    {
                        ((errorMsg.fieldName === 'adCategory')) && <span style={{ color: 'red', fontSize: '14px' }}>
                            {errorMsg.msg}
                        </span>
                    }
                </div>

                {/* Ad Title */}
                <div className='p-0'>
                    <span>Ad Title*</span>
                    {
                        <input
                            className={classes.inputBox + ' ' + classesAd.inputBox}
                            type='text'
                            id="name"
                            placeholder="Enter Ad Title"
                            value={form.adTitle}
                            onChange={(e) => handleInput(e.target.value, 'adTitle')}
                            // onKeyDown={(e) => setNameError('') || setNameError(e.target.value.length < 3 ? 'Name should be between 3 to 30 characters' : '')}
                            // onKeyUp={(e) => setNameError('') || setNameError(e.target.value.length < 3 ? 'Name should be between 3 to 30 characters' : '')}
                            min={3}
                            max={30}
                            required={true}
                        />
                    }
                    {
                        ((errorMsg.fieldName === 'adTitle')) && <span style={{ color: 'red', fontSize: '14px' }}>
                            {errorMsg.msg}
                        </span>
                    }
                </div>

                {/* Upload Video, thumbnail */}
                <div className='p-0'>
                    <RadioCustom option={'Upload File*'} checked={uploadVideo || form.adFileUrl !== ''} onSelect={() => { handleUploadVideo() }} />
                    {
                        ((errorMsg.fieldName === 'adFileUrl' || errorMsg.fieldName === 'thumbnailUrl')) && <span style={{ color: 'red', fontSize: '14px' }}>
                            {errorMsg.msg}
                        </span>
                    }
                    {
                        (uploadVideo || form.adFileUrl !== '') &&
                        <>
                            <div className={`col-12 ${classesAd.mediaDiv} pb-3 px-3 gap-3`}>
                                <div>
                                    <label htmlFor="" className={classes.labelPersonalize}>Upload Video*</label>

                                    {
                                        form.adFileUrl === '' ? (
                                            <>
                                                <div
                                                    style={{ width: "100%", border: '2px dashed #7A7A7A', borderRadius: "8px", height: '20vh', padding: '5px', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                                        onClick={() => videoRef.current.click()}
                                                    >
                                                        <img src={ImageIcon} alt="upload" />
                                                        <span className={classes.browseText}>Browse or Drag & Drop</span>
                                                    </div>
                                                    <input type="file"
                                                        className={classes.inputBox}
                                                        ref={videoRef}
                                                        accept="video/*"
                                                        style={{ display: 'None' }}
                                                        onChange={(e) => { handleFile(e, 'videoUrl') }}
                                                    />
                                                </div>
                                                {
                                                    ((errorMsg.fieldName === 'videoUrl')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                                        {errorMsg.msg}
                                                    </span>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className='pt-3'>
                                                    <video src={videoPreview || videPreview} autoPlay controls style={{ borderRadius: '8px', width: '100%' }} />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        ((errorMsg.fieldName === 'videoUrl')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                            {errorMsg.msg}
                                        </span>
                                    }
                                </div>

                                <div>
                                    <label htmlFor="" className={classes.labelPersonalize}>Upload Thumbnail*</label>
                                    {
                                        form.thumbnailUrl === '' ? (
                                            <>
                                                <div
                                                    style={{ width: "100%", border: '2px dashed #7A7A7A', borderRadius: "8px", height: '20vh', padding: '5px', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >

                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                                        onClick={() => thumbnailRef.current.click()}
                                                    >
                                                        <img src={ImageIcon} alt="upload" />
                                                        <span className={classes.browseText}>Browse or Drag & Drop</span>
                                                    </div>
                                                    <input type="file"
                                                        className={classes.inputBox}
                                                        ref={thumbnailRef}
                                                        accept="image/*"
                                                        style={{ display: 'None' }}
                                                        onChange={(e) => { handleFile(e, 'thumbnail') }}
                                                    />

                                                </div>
                                                {
                                                    ((errorMsg.fieldName === 'thumbnail')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                                        {errorMsg.msg}
                                                    </span>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <img src={thumbnailPreview || imgPreview} alt="thumbnail" style={{ borderRadius: '8px', width: '100%' }} />
                                            </>
                                        )
                                    }
                                </div>

                                <div className={'d-flex justify-content-center'}>
                                    <Button label={'Submit'} className={'signin active'} />
                                </div>
                            </div>
                        </>
                    }
                </div>

                {/* Ad Target */}
                <div className='p-0'>
                    <span>After viewers click your ad, where do you want to send them?</span>
                    {
                        <input
                            className={classes.inputBox + ' ' + classesAd.inputBox}
                            type='text'
                            id="name"
                            placeholder="Call to action url"
                            value={form.targetPath}
                            onChange={(e) => handleInput(e.target.value, 'targetPath')}
                            // onKeyDown={(e) => setNameError('') || setNameError(e.target.value.length < 3 ? 'Name should be between 3 to 30 characters' : '')}
                            // onKeyUp={(e) => setNameError('') || setNameError(e.target.value.length < 3 ? 'Name should be between 3 to 30 characters' : '')}
                            min={3}
                            max={30}
                            required={true}
                        />
                    }

                </div>

                <div className={'d-flex justify-content-center px-5'}>
                    <Button label={'Submit'} className={'cardButton active'} handleClick={submitPage} />
                </div>

            </div>



        </>
    )
}

export default PageOne;