import classesAd from './AdManagerForm.module.css';
import classes from '../Form.module.css';
import RadioCustom from '../RadioCustom';
import CheckBoxCustom from '../CheckBoxCustom';
import { useEffect, useState } from 'react';
import Button from '../../ui/Buttons';
import Select from 'react-select';
import reactSelectStyles from '../../../constants/react-select-style';
import useGetGenre from '../../../hooks/useGetGenre';
import useGetLocation from '../../../hooks/useGetLocation';

const PageOne = ({ form, setForm, handleNextClick }) => {
    const genres = useGetGenre();
    const [errorMsg, setErrorMsg] = useState({});
    const [locationOptions, setLocationOptions] = useState([]);
    const [location, setLocation] = useState('');
    const locationOption = useGetLocation({ payload: location });
    const options = ['Default (altRadar chooses for you)', 'Custom'];
    const [checkedOption, setCheckedOption] = useState('');
    const [custom, setCustom] = useState(false);
    const locationRangeOption = [
        { label: "within 5 miles", value: 5 },
        { label: "within 10 miles", value: 10 },
        { label: "within 20 miles", value: 20 },
    ]
    const [genreOptions, setGenreOptions] = useState([]);
    const genderOption = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }, { label: "Unknown", value: "Unknown" }]
    const ageOption = [
        { label: "18-24", value: { min: 18, max: 24 } }, { label: "25-34", value: { min: 25, max: 34 } },
        { label: "35-44", value: { min: 35, max: 44 } }, { label: "45-54", value: { min: 45, max: 54 } },
        { label: "55-64", value: { min: 55, max: 64 } }, { label: "65+", value: { min: 65, max: 150 } },
        { label: "unknown", value: { min: 0, max: 0 } }
    ]
    const handleAdType = (option) => {
        if (option === 'Default (altRadar chooses for you)') {
            setCustom(false);
            setForm({ ...form, 'alt_radar_choose': true });
            setCheckedOption(option);
        }
        if (option === 'Custom') {
            setCustom(true);
            setForm({ ...form, 'alt_radar_choose': false });
            setCheckedOption(option);
        }
    }

    useEffect(() => {
        const genre_ =
            genres?.map((genre) => {
                return ({ label: genre.name, value: genre.name })
            })
        setGenreOptions(genre_)
    }, [genres])

    useEffect(() => {
        if (locationOption === undefined) return;
        const data = locationOption.map(location => ({
            label: location.city,
            value: location.coordinates
        }));
        setLocationOptions(data);
    }, [locationOption]);

    const GetLocation = (selectedOption) => {
        setLocation(selectedOption);
    };

    const getOptions = (gen) => {
        return gen.map((gen) => {
            return { value: gen.value, label: gen.label }
        });
    }

    const saveLocation = (e) => {
        if ((e.label).length > 0)
            setForm({ ...form, 'location': { 'city': e.label, 'coordinates': e.value } });
    }

    useState(() => {
        if (form.alt_radar_choose) { setCheckedOption('Default (altRadar chooses for you)'); setCustom(false); }
        else { setCheckedOption('Custom'); setCustom(true); }
    }, [form.alt_radar_choose]);

    const handleMultipleOption = (option, type) => {
        if (type === 'gender') {
            const formGender = form.genderGroup;
            formGender.push(option);
            let data = [...new Set(formGender)];
            setForm({ ...form, 'genderGroup': data });
        }
        if (type === 'age') {
            const formAge = form.ageGroup;
            formAge.push(option);
            let data = [...new Set(formAge)];
            setForm({ ...form, 'ageGroup': data });
        }
    }

    const submitPage = () => {
        console.log('form :: ', form);
        if (!custom) {
            handleNextClick();
        } else {
            if (form.location.length === 0) {
                setErrorMsg({
                    fieldName: 'location',
                    msg: 'Please select location'
                })
                return;
            }
            if (form.locationRange === 0) {
                setErrorMsg({
                    fieldName: 'range',
                    msg: 'Please select location range'
                })
                return;
            }
            if (!form.genre.length) {
                setErrorMsg({
                    fieldName: 'genre',
                    msg: 'Please select genre'
                })
                return;
            }
            if (!form.genderGroup.length) {
                setErrorMsg({
                    fieldName: 'gender',
                    msg: 'Please select gener'
                })
                return;
            }
            if (!form.ageGroup.length) {
                setErrorMsg({
                    fieldName: 'age',
                    msg: 'Please select age'
                })
                return;
            }
            handleNextClick();
        }
    }

    return (
        <>
            <div className='row gap-3 pb-4 mb-4'>
                <div className='p-0'>
                    <form action="">
                        {
                            options.map((option, index) => {
                                return (
                                    <RadioCustom
                                        key={index}
                                        option={option}
                                        onSelect={() => { handleAdType(option) }}
                                        checked={checkedOption === option}
                                    />
                                )
                            })
                        }
                        {
                            custom && (
                                <>
                                    {/* location */}
                                    <label htmlFor="" className={classes.labelPersonalize}>Location*</label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        isClearable={false}
                                        options={getOptions(locationOptions)}
                                        isMulti={false}
                                        styles={reactSelectStyles}
                                        placeholder="Select location"
                                        onInputChange={GetLocation}
                                        onChange={(e) => { saveLocation(e) }}
                                        value={locationOptions.filter(option => option.label === form.location.city)}
                                    />
                                    {
                                        ((errorMsg.fieldName === 'location')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                            {errorMsg.msg}
                                        </span>
                                    }

                                    {/* location range  */}
                                    <form action=" ">
                                        <div className='d-flex gap-2'>
                                            {
                                                locationRangeOption.map((option, index) => {
                                                    return (
                                                        <RadioCustom
                                                            key={index}
                                                            option={option.label}
                                                            className={'fillBorder'}
                                                            onSelect={() => { setForm({ ...form, 'locationRange': option.value }) }}
                                                            checked={option.value === form.locationRange}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </form>
                                    {
                                        ((errorMsg.fieldName === 'range')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                            {errorMsg.msg}
                                        </span>
                                    }

                                    {/* genre */}
                                    <div className='p-0'>
                                        {
                                            <>
                                                <label htmlFor="" className={classes.labelPersonalize}>Genre*</label>
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    isClearable={false}
                                                    isMulti={false}
                                                    styles={reactSelectStyles}
                                                    onChange={(e) => { setForm({ ...form, 'genre': e.value }); setErrorMsg({ fieldName: 'genre', msg: '' }) }}
                                                    // isLoading={isLoading}
                                                    placeholder='Select genre'
                                                    options={genreOptions}
                                                    value={genreOptions.filter(option => option.value === form.genre)}
                                                />
                                            </>
                                        }
                                        {
                                            ((errorMsg.fieldName === 'genre')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                                {errorMsg.msg}
                                            </span>
                                        }
                                    </div>

                                    <form action="">
                                        <div className='p-0'>
                                            <label htmlFor="" className={classes.labelPersonalize}>Gender</label>

                                            <div className='d-flex gap-5'>
                                                {
                                                    genderOption.map((option, index) => {
                                                        return (
                                                            <CheckBoxCustom
                                                                key={index}
                                                                option={option.label}
                                                                onSelect={() => { handleMultipleOption(option.value, 'gender') }}
                                                                checked={option.value === form.genderGroup}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>{
                                                ((errorMsg.fieldName === 'gender')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                                    {errorMsg.msg}
                                                </span>
                                            }
                                        </div>
                                    </form>


                                    <form action=" ">
                                        <div className='p-0'>
                                            <label htmlFor="" className={classes.labelPersonalize}>Age</label>
                                            <div className='d-flex gap-3 flex-wrap'>
                                                {
                                                    ageOption.map((option, index) => {
                                                        return (
                                                            <CheckBoxCustom
                                                                key={index}
                                                                option={option.label}
                                                                onSelect={() => { handleMultipleOption(option.value, 'age') }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                ((errorMsg.fieldName === 'age')) && <span style={{ color: 'red', fontSize: '14px' }}>
                                                    {errorMsg.msg}
                                                </span>
                                            }
                                        </div>
                                    </form>
                                </>
                            )
                        }
                    </form>

                </div>

                <div className={'d-flex justify-content-center px-5'}>

                    <Button label={'Next'} className={'cardButton active'} handleClick={submitPage} />
                </div>

            </div>

        </>
    )
}

export default PageOne;