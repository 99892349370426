import Button from "../ui/Buttons";
import { setClearToken } from "../../store/redux/token/actions";
import { clearApplication, toggleSidebar } from "../../store/redux/application/actions";
import { useGetToggleSidebar, useGetRole } from '../../store/redux/application/hooks';
import { useGetUsername, useGetname, useGetUserProfilePicture, useGetUserEmail } from "../../store/redux/user/hooks";
// import { useGetUserCircleSso } from '../../store/redux/application/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import classes from './TopNavigation.module.css';
import settingIcon from '../../assets/images/svg/Navbar/Setting.svg';
import swapIcon from '../../assets/images/svg/Navbar/Swap.svg';
import logoutIcon from '../../assets/images/svg/Navbar/Logout.svg';
import SidealtRadar from '../../assets/images/svg/Sidebar/SidealtRadar.svg'
import SideDash from '../../assets/images/svg/Sidebar/SideDash.svg'
import defaultProfile from '../../assets/images/png/defaultIcon.png'

import { setClearApplication } from "../../store/redux/application/actions";
import { setClearUser } from "../../store/redux/user/actions";

import useGetUserSearchSuggestions from '../../hooks/useGetUserSearchSuggestions';
import { useGetUserSearchHistory } from "../../store/redux/user/hooks";
import { useEffect, useRef, useState } from "react";

import SearchHistoryDropDown from '../searchHistoryDropDown';
// import GetSearchSuggestion from '../../services/requests/httpRequest/GetSearchSuggestion';
import useQueryGraphql from '../../hooks/useQueryGraphql';
import { getSearchSuggestionsList } from '../../constants/graphQlQueries';
// import axios from "axios";
import CircleCookie from '../../services/requests/httpRequest/POC/circleCookie';
import CircleToken from '../../services/requests/httpRequest/POC/circleToken';

const TopNavigation = ({ circleSSO }) => {
    // const circleSSO = useMemo(() => useGetUserCircleSso(), []); // Memoizing circleSSO
    useGetUserSearchSuggestions();
    const userSearchHistory = useGetUserSearchHistory();
    const searchBarRef = useRef(null);
    const searchListRef = useRef(null);
    const [focused, setFocused] = useState(false);
    const [focusedList, setFocusedList] = useState(false);
    const Navigate = useNavigate();
    const Location = useLocation();
    const role = useGetRole();
    const username = useGetUsername();
    const profilePicture = useGetUserProfilePicture();
    const name = useGetname();
    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestionList, setSearchSuggestionList] = useState([]);
    const dispatch = useDispatch();

    const userEmail = useGetUserEmail();
    const logout = () => {
        dispatch(setClearToken());
        dispatch(clearApplication());
        dispatch(setClearUser());
        dispatch(setClearApplication());
    }
    const showSidebar = useGetToggleSidebar();
    const handleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleSearch = (event, type) => {
        const query = event.target.value;
        const currentParams = new URLSearchParams(Location.search); // Ensure currentParams is defined correctly

        if (Location?.pathname === '/gcp-youtube-poc') {
            // For history click
            if (type === 'history') {
                currentParams.set('search', query); // Set or update the search query parameter
                Navigate(`/gcp-youtube-poc?${currentParams.toString()}`); // Navigate to the path with updated params
                setFocusedList(false);
                setFocused(false);
            }

            // For pressing Enter key
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent default form submission behavior
                currentParams.set('search', query); // Set or update the search query parameter
                Navigate(`/gcp-youtube-poc?${currentParams.toString()}`);
            }
        } else {
            // Handling for other paths
            if (type === 'history') {
                Navigate(`/search?search=${encodeURIComponent(query)}`);
                setFocusedList(false);
                setFocused(false);
            }

            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent default form submission
                Navigate(`/search?search=${encodeURIComponent(query)}`);
            }
        }
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchBarRef.current &&
                !searchBarRef.current.contains(event.target) &&
                searchListRef.current &&
                !searchListRef.current.contains(event.target)
            ) {
                setFocused(false);
                setFocusedList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (focused) {
            searchBarRef.current.focus();
        } else {
            searchBarRef.current.blur();
        }
    }, [focused]);

    const unFocus = () => {
        if (focused && !focusedList) setFocused(false);
    }

    const focus = () => {
        if (!focused) setFocused(true);
    }

    const handleSearchInput = (e) => {
        const { value } = e.target;
        setSearchValue(value);
    }

    const [queryVariables, setQueryVariables] = useState({ searchKeyword: '', page: 1, limit: 10 });

    useEffect(() => {
        if (searchValue.length > 2) {
            const delayDebounceFn = setTimeout(() => {
                setQueryVariables({ searchKeyword: searchValue, page: 1, limit: 10 });
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchValue]);

    const {
        data
        // , loading, error, refetch
    } = useQueryGraphql(getSearchSuggestionsList, queryVariables);

    useEffect(() => {
        if (data) {
            const formattedData = data['getSearchSuggestionsList']?.list.map((item) => item.searchKeyword);
            setSearchSuggestionList(formattedData);
        }
    }, [data])

    // circle cookie set up
    // console.log('circleSSO --- ', circleSSO);

    useEffect(() => {
        if (circleSSO === true) {
            // Function to generate cookies by calling Circle.so API
            // const generateCookies = async (token) => {
            //     try {
            //         const response = await axios.post('https://backstage-pass-9a7a26.circle.so/api/headless/v1/cookies', null, {
            //             headers: {
            //                 'Authorization': `Bearer ${token}`,  // Use your actual access token here
            //                 'Content-Type': 'application/json'
            //             },
            //             withCredentials: true // Ensures cookies are sent and received
            //         });

            //         console.log('Response from Circle:', response);

            //         // Handle the Set-Cookie header (if applicable)
            //         if (response.status === 200) {
            //             console.log('Cookies generated and automatically set by the browser');
            //         } else {
            //             console.error('Failed to generate cookies:', response.data);
            //         }
            //     } catch (error) {
            //         console.error('Error generating cookies:', error);
            //     }
            // };

            // Function to generate Circle auth token
            // const generateCircleToken = async () => {
            //     try {
            //         const response = await axios.post('https://app.circle.so/api/v1/headless/auth_token', { email: userEmail }, {
            //             headers: {
            //                 'Content-Type': 'application/json',
            //                 'Authorization': 'czABa255fEJARQ8XVGJpzn3HmgKGiEjB'
            //             },
            //             withCredentials: true // Ensures cookies are sent and received
            //         });

            //         // Call generateCookies if access token is present
            //         if (response.data.access_token) {
            //             generateCookies(response.data.access_token);
            //         }
            //     } catch (error) {
            //         console.error('Error generating Circle token:', error);
            //     }
            // };

            const getCircleToken = async () => {
                const result = await CircleToken(userEmail);
                if (result?.data?.access_token) {
                    await setCookie(result.data.access_token);
                }
            }

            const setCookie = async (token) => {
                const result = await CircleCookie(token);
                document.cookie = result.data[2];
                console.log('result', result);
            }

            // Call the function to generate the Circle token
            // generateCircleToken();
            // generateCookies('eyJhbGciOiJSUzI1NiJ9.eyJjb21tdW5pdHlfaWQiOjIyNjQyMywiY29tbXVuaXR5X21lbWJlcl9pZCI6Mjc5MzI1MDIsImV4cCI6MTcyODkxMjEyNywidHlwZSI6ImhlYWRsZXNzX21lbWJlcl90b2tlbiIsImp0aSI6ImQxNjkwMWVlLTc0ZjUtNGY1OC04ZWVmLTEzNjMxZGFjYzFlNSJ9.abKzaxmfM22yTtZy-AGibQwyUScBr2Q8y4e2x4aSS4vipO8q_W6VcpRtgQsm4MrLyl-swr8PsN9IqpGFzsh2O4ZOdVA9h0COuuV-wjAW_A_7gfrLZ9Hf_XJ34255J09CblYAZNtsDs6PmtuK3k8BMCv-Jzk15BOruv0Dus-PpXAZNZpgO9VywudMt0_3ZOKw6Tklw4in5pOASiYTecIf2buVx2wSYrAprjYh_Q5-ABXNloPMI08be0K-oQCQuPh_bPl1V8mn38CwkpV9zeoAR3ori8AscNzdB6aB2A3u9mhomHjDzfFBap6nFvd5zWjuwzrZOZCp-kIe8RGDkufVKQ');
            // setCookie('eyJhbGciOiJSUzI1NiJ9.eyJjb21tdW5pdHlfaWQiOjIyNjQyMywiY29tbXVuaXR5X21lbWJlcl9pZCI6Mjc5MzI1MDIsImV4cCI6MTcyODkxNDM5NiwidHlwZSI6ImhlYWRsZXNzX21lbWJlcl90b2tlbiIsImp0aSI6IjNlYTY5NzFkLTg1NDMtNGUwMC1hZTFhLTE5ZTRkYTUxMDRjMiJ9.LN28jP5e88kOR_71yVCmjFhQs5F5ynVRyxVadk2P3qaNuHZs_pWa2w25mZyslK5Zl0wBwGPma46xbYvRJcIiSRi_ehl_MKeaN_SCKXyCPPKiMSB75yZw7-ogC8YQbijMM-SYValrAKTjvnOlGVlqubBP9EJC3oJIuZy8pnxi-Ff1JWrp3hRQij8u3Bt35AqskyO7x00T_t0V28l36QT1J67lI8Sp0jMiKI_b_3ToPElFJ1oE1ZJ_iNIXJUhoNSHG5SJDfhsqKgn-cmWWPuXfsgsY_g3ppN8IGFPMH1ujxjChofUmNHq8D-ct5OB0uxcUS5MzF-e5m-hpToVzfFVZwQ')
            getCircleToken();
        }
    }, [circleSSO, userEmail]);

    return (
        <>
            <div className="container-fluid m-0 p-0 d-flex justify-content-between" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000, background: '#000' }}>
                <div className='col-sm-3 col-md-3 col-lg-2 d-flex' style={{ background: showSidebar ? '#18181C' : '', padding: '5px 1rem 1rem 1rem', gap: '2rem' }}>
                    <img src={SideDash} alt="sidebar" onClick={handleSidebar} style={{ cursor: 'pointer', width: '1.5rem' }} />
                    <img className={`d-none d-sm-block`} src={SidealtRadar} alt="logo" style={{ width: '50%' }} />
                </div>

                <div className='col-4 d-none d-sm-block py-3 me-6'>
                    <form action=''>
                        <input type="text"
                            className={classes.searchInputField}
                            ref={searchBarRef}
                            placeholder={
                                Location?.pathname === '/gcp-youtube-poc' ? `Search for youtube videos` :
                                    `Search for albums, songs, singers, genres`}
                            value={searchValue}
                            onChange={(e) => { handleSearchInput(e) }}
                            onKeyDown={handleSearch}
                            onBlur={unFocus}
                            onFocus={focus}
                        />
                    </form>
                    {
                        focused && (
                            <SearchHistoryDropDown
                                HistoryArray={searchValue.length > 2 ? searchSuggestionList : userSearchHistory}
                                handleSearch={handleSearch}
                                ref_={searchListRef}
                                setFocusedList={setFocusedList}
                                showRecentImage={searchValue ? false : true}
                            />
                        )
                    }
                </div>

                <div className="col-md-4 d-flex justify-content-end py-3 px-2 pe-2">
                    <div className="d-flex">
                        <Button className={'circle message'} handleClick={() => Navigate('/messages')} />
                        {role !== 'USER' &&
                            <Button className={'circle uploadVideo'} handleClick={() => Navigate('/upload')} />
                        }
                        <Button className={'circle notification'} handleClick={() => Navigate('/notifications')} />
                        <div className="dropdown">
                            <Button className={'dropdown-toggle circle profile'} toggle={"dropdown"}
                                backImg={`url(${profilePicture || defaultProfile})`}
                            />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item" style={{
                                    height: '4rem',
                                    borderBottom: '1px solid grey',
                                    borderWidth: 'thin',
                                    alignItems: 'center',
                                }}>
                                    <div className="d-flex p-0 m-0" onClick={() => Navigate('/profile')}>
                                        <img
                                            style={{
                                                width: '2rem',
                                                height: '2rem',
                                                borderRadius: '50%',
                                                margin: '0.5rem',
                                            }} src={profilePicture || defaultProfile} alt="" />
                                        <div className="p-0 m-0">
                                            <p className="m-0  p-0">{username || name}</p>
                                            <p className="m-0  p-0">Free</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <span className="dropdown-item" onClick={() => Navigate('/settings')}><img src={settingIcon} alt="settings" />&nbsp; Settings</span>
                                    <span className="dropdown-item"><img src={swapIcon} alt="swap profile" />&nbsp; Profile Switch</span>
                                    <span className="dropdown-item" onClick={logout} ><img src={logoutIcon} alt="logout" /> &nbsp;  Logout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopNavigation;
