import classes from './AdDemographics.module.css';

const AdDemographicsTabs = ({ handleActiveTab, activeTab }) => {

    return (
        <>
            <div className={`col-8 ${classes.tabs} pt-3`}>
                <span className={activeTab ? `${classes.tab} ${classes.activeTab}` : classes.tab} onClick={() => { !activeTab && handleActiveTab(activeTab) }}>Ad Info</span>
                <span className={!activeTab ? `${classes.tab} ${classes.activeTab}` : classes.tab} onClick={() => { activeTab && handleActiveTab(activeTab) }}>Ad Analytics</span>
            </div>
        </>
    )
}

export default AdDemographicsTabs;