import { getLocation } from "../services/requests/httpRequest/serverApi";
import { useEffect, useState } from "react";

export const useGetLocation = ({ payload }) => {
    const [data, setData] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getLocation({ input: payload });
                setData(result.data);
            } catch (error) {
                console.error("Error fetching genre data:", error);
                setData([]);
            }
        };
        if (payload.length > 0) {
            fetchData();
        }
    }, [payload]);

    return data;
}

export default useGetLocation;
