import useGetWelcomeGenres from '../../../hooks/useGetWelcomeGenres';
import Genre from '../Genres';

const TopGenre = () => {
    const response = useGetWelcomeGenres();
    return (
        <>
            {response.map((genre, index) => (
                <Genre genre={genre.name} key={index} />
            ))}
        </>
    )
}
export default TopGenre;