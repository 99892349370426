import classes from '../AdDemographics.module.css';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ArcElement,
    LineElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const NoOfViews = () => {

    const barGraphData = {
        labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
        datasets: [
            {
                label: 'LABEL',
                backgroundColor: ['#41B2D6', '#FF7700', '#EF7DAE'],
                width: 10,
                barThickness: 12,
                data: [200, 40, 100, 300, 50, 350, 250],
                borderRadius: 10,
                borderSkipped: false,
                fill: false
            },
        ],
    };

    const barOptions = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const selectStyle2 = {

        control: (base, state) => ({
            ...base,
            background: "#F74037",
            // match with the menu
            borderRadius: '5px',
            // Overwrittes the different states of border
            borderColor: 'none',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: '#707070'
            },
            borderBottom: '1px solid #707070',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',

        }),
        menuList: styles => ({
            ...styles,
            background: '#313131',
            color: '#fff',
            borderRadius: '5px',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? '#656565'
                : isSelected
                    ? '#656565'
                    : undefined,
            zIndex: 1,
            height: '5%',
            fontSize: '12px',
        }),
        menu: base => ({
            ...base,
            zIndex: 100,
        }),
        indicatorsContainer: (prevStyle, state) => state.isMulti ? ({
            ...prevStyle,
            display: 'none'
        }) : null,
        input: styles => ({ ...styles, color: '#fff', padding: '0rem' }),
        placeholder: styles => ({ ...styles, background: '#F74037', margin: '0 10px', color: '#fff', fontSize: 'clamp(12px, 4vw, 14px)' }),
        valueContainer: styles => ({ ...styles, padding: '0rem' }),
        multiValue: styles => ({ ...styles, background: '#707070', color: '#fff', }),
        multiValueLabel: styles => ({ ...styles, color: '#fff' }),
    }

    return (
        <>
            <div className={classes.NoOfViews}>
                <div className={classes.noViews2}>
                    <span className={classes.viewText}>
                        No. of views
                    </span>
                    <Select
                        name="viewSelect"
                        id="viewSelect"
                        placeholder='Last Week'
                        styles={selectStyle2}
                        isSearchable={false}
                    />
                </div>
                <hr class="border-bottom border-1 border-light"></hr>
                <Bar id='adAnalyticsViews' className='w-100' data={barGraphData} options={barOptions} />
            </div>
        </>
    )
}

export default NoOfViews;