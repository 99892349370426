import classes from '../AdDemographics.module.css';
import { Doughnut } from 'react-chartjs-2';
import RadioCustom from '../../../forms/RadioCustom';
import { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ArcElement,
    LineElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const PieChart = () => {

    const doughnutGraphData = {
        labels: ['Male', 'Female', 'Others', 'Unknown'],
        datasets: [
            {
                data: [50, 50, 30, 20],
                backgroundColor: ['#50758D', '#2EDFFC', '#FAD207', '#FD646F'],
                hoverOffset: 7,
                borderColor: ['#50758D', '#2EDFFC', '#FAD207', '#FD646F'],
            },
        ],
    };

    const doughnutOptions = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: 'white', // Change label text color to white
                },
            },
        },
        layout: {
            padding: {
                top: 50,
                bottom: 50,
            },
        },
        radius: '100%',
    };

    const option = ['18-30', '30-40', '40-60', '60+']
    const [selectedOptions, setSelectedOptions] = useState('18-30');

    return (
        <>
            <div className={classes.chart}>
                <div className={`${classes.noViews2} justify-content-center`}>
                    <span className={classes.viewText}>
                        Clicks GENDER & AGE groups
                    </span>
                </div>
                <div className='d-flex w-100 justify-content-center gap-4 mt-4'>
                    {option.map((option) => (
                        <RadioCustom key={option} option={option} className={''} checked={selectedOptions === option} onSelect={() => setSelectedOptions(option)} />
                    ))}
                </div>
                <Doughnut data={doughnutGraphData} options={doughnutOptions} style={{ width: '400px', height: '300px' }} /> {/* Adjusted size */}
            </div>
        </>
    )
}

export default PieChart;
