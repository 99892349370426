import { videoReaction } from "../../../services/requests/httpRequest/serverApi";

export const VideoReactionCall = async ({ reactedOn, reactionType, isUnreacted, reactionStatus }) => {
    try {
        const data = {
            "reactedOn": reactedOn,
            "reactionType": reactionType,
            "isUnreacted": isUnreacted,
            "reactionStatus": reactionStatus
        }
        const result = await videoReaction(data);
        return result;
    } catch (error) {
        console.error("Error fetching genre data:", error);
    }

}

export default VideoReactionCall;
