import { useEffect, useState } from 'react';
import { getWelcomeGenres } from '../services/requests/httpRequest/serverApi';

const useGetWelcomeGenre = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getWelcomeGenres();
                setData(response.data);
            } catch (error) {
                console.error("Error fetching genre data:", error);
                setData([]);
            }
        };

        fetchData();
    }, []);

    return data;
};

export default useGetWelcomeGenre;
