import React from 'react';
import classes from './Button.module.css';

const Button = ({ label, handleClick, className, toggle, disable, backImg }) => {
    // Split the className string into an array of class names
    let classNames = '';
    if (className !== undefined && className !== null && className !== '') {
        classNames = className.split(' ').map(cn => classes[cn]).join(' ');
    }
    // console.log('classNames :: ', classNames);

    return (
        <button
            className={classNames}
            style={{ textTransform: 'uppercase', cursor: 'pointer', backgroundImage: backImg && backImg }}
            onClick={handleClick}
            data-bs-toggle={toggle} // Add the data-bs-toggle attribute
            disabled={disable}
        >
            {label}
        </button>
    );
};

export default Button;
