import classes from './AdManager.module.css';
import PageOneForm from '../../forms/AdManagerForm/pageone';
import sampleImage from '../../../assets/images/svg/adManagerSample.svg'

const PageOne = ({ form, setForm, handleNextClick, setImgPreview, setVidPreview, imgPreview, videPreview }) => {
    return (
        <>
            <div className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}>
                <div className='col-6 gap-3 d-flex flex-column'>
                    {
                        videPreview
                            ?
                            <video src={videPreview} autoPlay controls className='w-100' style={{ borderRadius: '8px' }} />
                            :
                            <img className='w-100' src={sampleImage} alt="" />
                    }
                    <img className='w-100' src={imgPreview || sampleImage} alt="" style={{ borderRadius: '8px' }} />
                </div>
                <div className='col-5 d-flex'>
                    <PageOneForm
                        form={form}
                        setForm={setForm}
                        handleNextClick={handleNextClick}
                        setImgPreview={setImgPreview}
                        setVidPreview={setVidPreview}
                        imgPreview={imgPreview}
                        videPreview={videPreview}
                    />
                </div>
            </div>
        </>
    )
}

export default PageOne;