import VideoCarousel from "../../../components/ui/VideoCarousel";
import live1 from '../../../assets/images/svg/RadarPage/live1.svg';
import useGetWelcomeVideos from "../../../hooks/useGetWelcomeVideos";

const Currentoriginalsongsweeklycontest = ({ page }) => {

    const data = useGetWelcomeVideos({ page: page, limit: 10, type: 'currentWeekly', vidType: 'Original' });

    if (!data || data.length === 0) {
        return null;
    }

    return (
        data?.length &&
        <VideoCarousel data={{ list: data }} img={live1} title={'Current Weekly (Original)'} participate={true} />
    )

}

export default Currentoriginalsongsweeklycontest

