import AdDemographicsComponent from '../../../components/Advertisement/Demographics';


const AdDemographicsPage = () => {
    return (
        <>
            <AdDemographicsComponent />
        </>
    )
}

export default AdDemographicsPage