import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '../../store/redux';
const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://18.204.254.128:8070';

const httpLink = new HttpLink({
	uri: `${baseUrl}/fetch`,
});

const authLink = setContext((_, { headers }) => {
	const token = store.getState()._session.access_token;
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});
export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(httpLink),
});
