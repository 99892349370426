import classes from './AdManager.module.css';
import GoBackComponent from '../../GoBack';
import Progress from './progress';
import { useState } from 'react';

import PageOne from './pageone';
import PageTwo from './pagetwo';
import PageThree from './pagethree';
import PageFour from './pagefour';

import NewAdvertisement from '../../../services/requests/httpRequest/Advertisement/NewAdvertisement';

const AdManagerComponent = () => {
    const [page, setPage] = useState(1);
    const [imgPreview, setImgPreview] = useState('');
    const [vidPreview, setVidPreview] = useState('');
    const [totalDays, setTotalDays] = useState(0);
    const [customDays, setCustomDays] = useState(false);

    const defForm = {
        adCategory: '',
        adTitle: '',
        adFileUrl: '',
        adFileType: 'video',
        adVidFileType: 'video',
        targetPath: '',
        location: [],
        locationRange: 0,
        genre: [],
        genderGroup: [],
        ageGroup: [],
        budget: 0.0,
        dateOfPost: new Date(),
        endDate: "",
        videoId: '',
        alt_radar_choose: true,
        isExpire: false,
        paymentStatus: '',
        thumbnailUrl: '',
        status: '',
        _id: '',
    }

    const [form, setForm] = useState(defForm);

    const handleNextClick = () => {
        setPage(page + 1);
    }

    const handleBackClick = () => {
        setPage(page - 1);
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('adCategory', form.adCategory);
        formData.append('adTitle', form.adTitle);

        formData.append('adFileUrl', form.adFileUrl);
        formData.append('adFileType', form.adFileType);
        formData.append('adVidFileType', form.adVidFileType);
        formData.append('videoId', form.videoId);
        formData.append('thumbnailUrl', form.thumbnailUrl);

        formData.append('budget', form.budget);
        formData.append('dateOfPost', form.dateOfPost);
        formData.append('endDate', form.endDate);
        formData.append('alt_radar_choose', form.alt_radar_choose);
        formData.append('isExpire', form.isExpire);
        formData.append('paymentStatus', form.paymentStatus);

        formData.append('targetPath', form.targetPath);
        formData.append('location', form.location);
        formData.append('locationRange', form.locationRange);
        formData.append('genre', form.genre);
        formData.append('status', form.status);

        form.genderGroup.map((x) => formData.append('genderGroup', x));
        form.ageGroup.map((x) => formData.append('ageGroup', x));

        // console.log('formData :: ', formData, form);
        await NewAdvertisement(formData);
    }

    return (
        <>
            <div className='container'>
                <GoBackComponent handleBack={page > 1 ? handleBackClick : null} />
                <Progress currentPage={page} />
                {page === 1 && <span className={classes.letscreate}>LETS CREATE YOUR AD !</span>}
                {page === 2 && <span className={classes.letscreate}>Next, Choose the locations, age, and gender of your customers</span>}
                {page === 3 && <span className={classes.letscreate}>Almost done! Set a budget that's right for you</span>}
                {page === 4 && <span className={classes.letscreate}>Review your campaign to be sure it's how to want it</span>}
            </div>

            {page === 1 && <PageOne
                form={form}
                setForm={setForm}
                handleNextClick={handleNextClick}
                setImgPreview={setImgPreview}
                setVidPreview={setVidPreview}
                imgPreview={imgPreview}
                videPreview={vidPreview}
            />}
            {page === 2 && <PageTwo
                handleNextClick={handleNextClick}
                setForm={setForm}
                form={form}
                vidPreview={vidPreview}
            />}
            {page === 3 && <PageThree
                handleNextClick={handleNextClick}
                form={form}
                setForm={setForm}
                vidPreview={vidPreview}
                setTotalDays={setTotalDays}
                totalDays={totalDays}
                setCustomDays={setCustomDays}
                customDays={customDays}
            />}
            {page === 4 && <PageFour
                // handleNextClick={handleNextClick}
                form={form}
                totalDays={totalDays}
                vidPreview={vidPreview}
                submit={handleSubmit}
            />}
        </>
    )
}

export default AdManagerComponent;