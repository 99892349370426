import { emailSendToken } from './serverApi';
import CustomToast from '../../../utils/CustomToast';

const MailSendToken = async (mail, type) => {
    const payload = {
        "mail": mail,
        "type": type
    }
    try {
        let result = await emailSendToken(payload);
        CustomToast(result.message, "success");
        return result.status;
    }
    catch (e) {
        // CustomToast('Unable to send mail, please try again', "error");
    }
}

export default MailSendToken