import './App.css';

import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { store, persistor } from './store/redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from './Navigation';
import { client } from './services/apolloClient';
import { PersistGate } from "redux-persist/integration/react";
import SocketIO from './services/socketIO/SocketIO';
import CircleWidget from './components/helmet/circleWidget';

const Providers = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter onUpdate={() => window.scrollTo(0, 0)} history={useNavigate}>
            <SocketIO />
            {children}
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
};

function App() {
  console.log('render App.js:: ');
  return (
    <Providers>
      <CircleWidget />
      <ToastContainer />
      <Navigation />
    </Providers>
  );
}

export default App;
