import VideoCarousel from "../../../components/ui/VideoCarousel";
import live1 from '../../../assets/images/svg/RadarPage/live1.svg';
import useGetWelcomeVideos from "../../../hooks/useGetWelcomeVideos";

const Currentcoversongsweeklycontest = ({ page }) => {
    const data = useGetWelcomeVideos({ page: page, limit: 10, type: 'currentWeekly', vidType: 'Cover' });

    if (!data || data.length === 0) {
        return null; 
    }

    return (
        data?.length &&
        <VideoCarousel data={{ list: data }} img={live1} title={'Current Weekly (Cover)'} participate={true} />
    )
}

export default Currentcoversongsweeklycontest



