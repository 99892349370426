import classes from '../../forms/Form.module.css';
import Button from '../../ui/Buttons';
import { useEffect, useState } from 'react';
import GetCalculations from '../../../services/requests/httpRequest/Advertisement/GetCalculations';

const PageOne = ({ handleNextClick, form, totalDays, vidPreview, submit }) => {

    const [impressions, setImpressions] = useState('');
    const [views, setViews] = useState('');
    const [clicks, setClicks] = useState('');
    const [ageList, setAgeList] = useState([]);

    useEffect(() => {
        handleGetCalculations(form.adCategory, form.budget, totalDays);
        (form.ageGroup).map((age) => {
            setAgeList((oldArray) => [...oldArray, `${age.min}-${age.max}`]);
        });
    }, [form, totalDays])

    const handleGetCalculations = async (adCategory, amount, totalDays) => {
        const result = await GetCalculations(adCategory, amount, totalDays);
        if (result) {
            setImpressions(result.data.impressions.min_total + ' - ' + result.data.impressions.max_total);
            setViews(result.data.views.min_total + ' - ' + result.data.views.max_total);
            setClicks(result.data.clicks.min_total + ' - ' + result.data.clicks.max_total);
        }
    }

    return (
        <>
            <div className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}>
                <div className='col-6'>
                    <div className='row gap-4 p-4' style={{ background: '#18181C', borderRadius: '10px' }}>
                        <div className='d-flex flex-column gap-1' style={{ borderBottom: '1px solid #FFFFFF' }}>
                            <span>Estimated Advertisement Response</span>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>{impressions}</span>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>Impressions</span>
                        </div>
                        <div className='d-flex flex-column gap-1' style={{ borderBottom: '1px solid #FFFFFF' }}>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>{views}</span>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>Views</span>
                        </div>
                        <div className='d-flex flex-column gap-1' style={{ borderBottom: '1px solid #FFFFFF' }}>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>{clicks}</span>
                            <span style={{ fontSize: 'clamp(8px, 2vw, 12px)' }}>Clicks</span>
                        </div>
                    </div>
                </div>
                <div className='col-5 d-flex'>
                    <div className='row gap-3 pb-4 mb-4'>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Ad Category</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form.adCategory}
                                disabled
                            />
                        </div>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Ad Title</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form.adTitle}
                                disabled
                            />
                        </div>
                        <div className='p-0 d-flex flex-column'>
                            <label htmlFor="" className={`${classes.labelPersonalize}`}>Your Ad file</label>
                            <video className='w-100' src={vidPreview} autoPlay controls style={{ borderRadius: '8px' }} />
                        </div>

                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Call to action</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form.targetPath || '---'}
                                disabled
                            />
                        </div>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Locations</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form.location.city || 'Default'}
                                disabled
                            />
                        </div>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Age</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form['alt_radar_choose'] ? 'Default' : ageList.join(', ')}
                                disabled
                            />
                        </div>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Gender</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={form?.genderGroup.length ? form.genderGroup.map((x) => x).join(', ') : 'Default'}
                                disabled
                            />
                        </div>
                        <div className='p-0'>
                            <label htmlFor="" className={classes.labelPersonalize}>Budget</label>
                            <input
                                className={`${classes.inputBox}`}
                                type="text"
                                placeholder=""
                                value={`$ ${form.budget}`}
                                disabled
                            />
                        </div>
                        <div className={'d-flex justify-content-center px-5'}>
                            <Button label={'Post'} className={'cardButton active'} handleClick={submit} />
                        </div>
                    </div>

                </div>

            </div>


        </>
    )
}

export default PageOne;