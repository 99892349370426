import { useNavigate } from "react-router-dom";

const GoBackComponent = ({ handleBack }) => {
    const navigate = useNavigate();
    return (
        <div
            style={{ cursor: 'pointer', width: 'fit-content' }}
            onClick={() => handleBack ? handleBack() : navigate(-1)}
            role="button"
            onKeyDown={() => navigate(-1)}
        >
            <i class="fa fa-angle-left"></i>
        </div>
    )
}

export default GoBackComponent;