import VideoCarousel from "../../../components/ui/VideoCarousel"
import useGetWelcomeVideos from "../../../hooks/useGetWelcomeVideos";

const Genre = (props) => {
    const data = useGetWelcomeVideos({ page: 1, limit: 10, genre: props.genre, type: 'viral' });
    return (
        <>
            <VideoCarousel data={{ list: data }} title={`${props.genre}`} />
        </>
    )
}

export default Genre;