import { appleFeedGenreSongs } from "../serverApi";

const AppleFeedGenreSongs = async (id, page, limit) => {
    try {
        const payload = {
            id: id,
            page: page,
            limit: limit
        }
        const result = await appleFeedGenreSongs(payload);
        return result;
    } catch (error) {
        // CustomToast(error.message, 'error');
        console.log('error', error);
    }
}

export default AppleFeedGenreSongs;