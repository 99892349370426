import { useState, useEffect } from 'react';
const ScreenSize = () => {

    const [screenSize, setScreenSize] = useState(getScreenSize());

    function getScreenSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    useEffect(() => {
        function handleResize() {
            setScreenSize(getScreenSize());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
}

export default ScreenSize;