import classes from './Form.module.css';

const CheckBoxCustom = ({ option, onSelect, checked, className, disable }) => {
    return (
        <>
            <label className={`${classes.container} ${classes[`${className}`]}`}>
                {/* <input type="radio" checked={checked} name={'radio'} onChange={onSelect} disabled={disable} /> */}
                <input type="checkbox" id={option} name={option} value={option} onChange={onSelect} />
                <span className={classes.checkmark}></span>
                {option}
            </label>
        </>
    )
}

export default CheckBoxCustom;