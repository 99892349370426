// import Button from 'react-bootstrap/Button';
// import Button from '../Buttons';
import Modal from 'react-bootstrap/Modal';
// import classes from './modal.module.css';
import './modal.css';


/**
 * Renders a Bootstrap modal component.
 *
 * @param {boolean} show - Determines if the modal is visible.
 * @param {boolean} showHeader - Determines if the modal header is displayed.
 * @param {function} handleClose - Function to handle the modal close event.
 * @param {function} handleSubmit - Function to handle the modal submit event.
 * @param {string} title - Title of the modal.
 * @param {ReactNode} body - Content of the modal body.
 * @param {ReactNode} footer - Content of the modal footer.
 * @param {string} className - Custom CSS class for the modal.
 * @param {string} size - Size of the modal.
 */
function BootstrapModal({ show, showHeader, handleClose, handleSubmit, title, body, footer, className, size }) {
    return (
        <>
            <Modal
                size={size}
                className={className}
                show={show} onHide={handleClose} backdrop="static" keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                data-bs-theme="dark"
            >
                {showHeader &&
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                }

                <Modal.Body>{body}</Modal.Body>
                {footer &&
                    <Modal.Footer>
                        {footer}
                    </Modal.Footer>}

            </Modal>
        </>
    );
}

export default BootstrapModal;