import { useEffect, useState } from 'react';
import { getWelcomeVideos } from '../services/requests/httpRequest/serverApi';

const useGetWelcome = (Props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let payload;

                payload = {
                    page: Props.page,
                    limit: Props.limit,
                    type: Props.type,
                };

                if (Props.vidType) payload.vidType = Props.vidType;
                if (Props.genre) payload.genre = Props.genre;

                const response = await getWelcomeVideos(payload);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching genre data:", error);
                setData([]);
            }
        };

        fetchData();
    }, []);

    return data;
};

export default useGetWelcome;
