import { toast, Slide, } from 'react-toastify';

const CustomToast = (message, type) => {
    const options = {
        type: "default",
        theme: 'dark',
        position: "bottom-left",
        autoClose: 4000,
        pauseOnFocusLoss: false,
        transition: Slide
    }

    switch (type) {
        case 'success':
            options.type = 'success';
            break;
        case 'info':
            options.type = 'info';
            break;
        case 'warning':
            options.type = 'warning';
            break;
        case 'error':
            options.type = 'error';
            break;
        default:
            options.type = 'default';
            break;
    }

    toast(message, options);
}

export default CustomToast