import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import tokenReducer from './token/reducer';
import userReducer from './user/reducer';
import applicationReducer from './application/reducer';
import encryptionTransform from '../../utils/encryptionTransform';

const rootReducer = combineReducers({ // Combine all reducers
    _session: tokenReducer,
    _static: userReducer,
    _app: applicationReducer,
    // add other reducers below
});

const NEW_VERSION = 0.16;
const migration = {
    [NEW_VERSION]: state => {
        return {
            ...state,
            _session: tokenReducer,
            _static: userReducer,
            _app: applicationReducer,
        }
    }
}

const persistConfig = {
    key: 'root',
    storage,
    manualPersist: true, // Set manualPersist to true
    whitelist: ['_session', '_static', '_app'], // Only persist these reducers
    blacklist: [], // blacklist other reducers
    version: NEW_VERSION, // Set the current version of your state
    transforms: [encryptionTransform],
    migrate: createMigrate(migration, { debug: false }), // migrate from previous version
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false // Disable serializable check
        })
});

// Persist store with options
const persistor = persistStore(store, null, () => {
    // Callback function after rehydration is finished
    // console.log('Rehydration is finished.');
});

export { store, persistor };
