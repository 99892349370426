import classes from '../AdDemographics.module.css';
import {
    adDemoAnalyticViewBg,
    adDemoAnalyticClickBg
} from '../../../../utils/images';

const Card = () => {
    return (
        <>
            <div className='d-flex col-12 gap-3'>
                <div className={`${classes.card} ${classes.cardAnlt} m-2`}>
                    <p className={classes.cardTitleDataAnalytic}>Total Views</p>
                    <p className={classes.cardAnalyticNumber}>{`635`}</p>
                    <img className={classes.cardAnalyticBgView} src={adDemoAnalyticViewBg} alt="viewbg" />
                </div>
                <div className={`${classes.card} ${classes.cardAnlt} m-2`}>
                    <p className={classes.cardTitleDataAnalytic}>Total Clicks</p>
                    <p className={classes.cardAnalyticNumber}>{`365`}</p>
                    <img className={classes.cardAnalyticBg} src={adDemoAnalyticClickBg} alt="viewbg" />
                </div>
            </div>
        </>
    )
}

export default Card;