import { circleTokenApi } from "../serverApi";

const CircleToken = async (email) => {
    try {
        const payload = {
            email: email,
        }
        const result = await circleTokenApi(payload);
        return result;
    } catch (error) {
        // CustomToast(error.message, 'error');
        console.log('error', error);
    }
}

export default CircleToken;