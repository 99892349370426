import { createAction } from '@reduxjs/toolkit';

export const setUsername = createAction("user/setUsername")
export const updateUserProfile = createAction('user/updateUserProfile')
export const setUserProfile = createAction('/user/setUserProfile')
export const setProfileExists = createAction('user/setProfileExists')
export const setUserSearchHistory = createAction("user/setUserSearchHistory")
export const setSearchSuggestionList = createAction("user/setSearchSuggestionList")
export const setClearUser = createAction("user/setClearUser")
export const setUserId = createAction("user/setUserId")
export const setUserProfileFollowers = createAction("user/setUserProfileFollowers")
export const setUserPrivacyStore = createAction("user/setUserPrivacyStore")
export const setUserNotificationStore = createAction("user/setUserNotificationStore")