import Modal from '../../components/ui/Modal';
import { useState } from 'react';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

const ForgotPassword = () => {

    const [showModal, setshowModal] = useState(true);
    return (
        <>
            <Modal
                shouldShow={showModal}
                onRequestClose={() => {
                    setshowModal((prev) => !prev);
                }}
                children={<ForgotPasswordForm />}
            />
        </>
    )
}

export default ForgotPassword