import classes from './Form.module.css';

const RadioCustom = ({ option, onSelect, checked, className, disable }) => {
    return (
        <>
            <label className={`${classes.container} ${classes[`${className}`]}`}>
                <input type="radio" checked={checked} name={'radio'} onChange={onSelect} disabled={disable} />
                <span className={classes.checkmark}></span>
                {option}
            </label>
        </>
    )
}

export default RadioCustom;