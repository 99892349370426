import { musicLoader } from '../../../utils/images';

const MusicLoader = () => {
    return (
        <>
            <img src={musicLoader} className={'w-100 h-50'} alt="loading" />
        </>
    )
}

export default MusicLoader