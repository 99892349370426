import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dots from "../../assets/images/svg/LandingPage/svg/WelcomeBanner/dots.svg";
import music from "../../assets/images/svg/LandingPage/svg/WelcomeBanner/music.svg";
import { useGetAccessToken } from "../../store/redux/token/hooks";
const NotFoundPage = () => {
    const accessToken = useGetAccessToken();
    const navigation = useNavigate();
    const [time, setTime] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (time === 0) {
            navigation(accessToken ? "/radar" : "/");
        }
    }, [time, navigation, accessToken]);

    return (
        <>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                <h1>404 - Not Found</h1>
                <p>The page you are looking for does not exist.</p>
                <p>Redirecting to the {accessToken ? "radar" : "landing"} page in {time} seconds</p>
            </div>

            <img
                src={dots}
                alt="dots"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "100%",
                    height: "100%",
                    objectFit: "none",
                    zIndex: -1,
                }}
            />
            <img
                src={music}
                alt="music"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "90%",
                    transform: "translate(-50%,-50%)",
                    width: "100%",
                    height: "100%",
                    objectFit: "none",
                    zIndex: -1,
                }}
            />
        </>

    )
}

export default NotFoundPage;