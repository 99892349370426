import { appleFeedGenre } from "../serverApi";

const AppleFeedGenre = async (email) => {
    try {
        // const payload = {
        //     email: email,
        // }
        const result = await appleFeedGenre();
        return result;
    } catch (error) {
        // CustomToast(error.message, 'error');
        console.log('error', error);
    }
}

export default AppleFeedGenre;