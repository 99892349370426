import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setExpiryToken, setJWT, setRefreshToken, setClearToken } from './actions';

// dispatchers

export function useSetJwt(jwt) {
    const dispatch = useDispatch();
    return useCallback((jwt) => dispatch(setJWT({ jwt })), [dispatch]);
}

export function useSetAccessToken(accessToken) {
    const dispatch = useDispatch();
    return useCallback((accessToken) => dispatch(setAccessToken({ accessToken })), [dispatch]);
}

export function useSetRefreshToken(refreshToken) {
    const dispatch = useDispatch();
    return useCallback((refreshToken) => dispatch(setRefreshToken({ refreshToken })), [dispatch]);
}

export function useSetExpiryToken(expiryToken) {
    const dispatch = useDispatch();
    return useCallback((expiryToken) => dispatch(setExpiryToken({ expiryToken })), [dispatch]);
}

export function useClearToken() {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(setClearToken({})), [dispatch]);
}

// selectors

export function useGetJwt() {
    return useSelector((state) => state._session.jwt)
}

export function useGetAccessToken() {
    return useSelector((state) => state._session.access_token)
}

export function useGetRefreshToken() {
    return useSelector((state) => state._session.refresh_token)
}

export function useGetExpiryToken() {
    return useSelector((state) => state._session.expiry_token)
}

