import Button from "../../ui/Buttons";
// import { NavLink } from "react-router-dom";
import BootstrapModal from "../../ui/Bootstrap/modal";
import Heading from "../NewAdPrompt/Heading";
import Body from "../NewAdPrompt/Body";
import { useState } from "react";

const AddNew = () => {
    const [show, setShow] = useState(false);
    return (
        <>
            {
                show && <BootstrapModal
                    size={'lg'}
                    showHeader={true}
                    show={show}
                    className={'black-modal-advertisement'}
                    setShow={setShow}
                    title={<Heading />}
                    body={<Body />}
                />
            }
            {/* <NavLink to={'/advertisement/upload/new'} className={'w-50'}> */}
            <Button
                className={'signinas active'}
                label={'+ New Ad'}
                handleClick={() => setShow(true)}
            />
            {/* </NavLink> */}

        </>
    )
}

export default AddNew;