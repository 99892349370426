import { getTrending } from "../services/requests/httpRequest/serverApi";
import { useEffect, useState } from "react";

export const useGetTrending = ({ page, limit }) => {
    const [data, setData] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = {
                    page: page,
                    limit: limit
                }
                const result = await getTrending(data);
                setData(result.data);
            } catch (error) {
                console.error("Error fetching genre data:", error);
                setData([]);
            }
        };
        if (page && limit) {
            fetchData();
        }
    }, [page, limit]);

    return data;
}

export default useGetTrending;
