// import classes from './SeeMoreOption.module.css';

const SeeMoreOptionUI = ({ handleClick }) => {
    return (
        <>
            <div
                className='mb-2 d-flex align-items-center justify-content-end w-100 gap-2'
                style={{ cursor: 'pointer' }}
            >
                See more {' '}
                <i className="fas fa-arrow-right"
                    onClick={() => { handleClick() }}
                >
                </i>
            </div>
        </>
    )
}

export default SeeMoreOptionUI;