import classes from './NewAdPrompt.module.css';
import { useState } from 'react';
import Button from '../../ui/Buttons';
import { useNavigate } from 'react-router-dom';

const NewAdPrompt = () => {
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        checked &&
            navigate('/advertisement/upload/new')
    }

    return (
        <>
            <div className='gap-2 d-flex flex-column'>
                <span className={classes.body}>
                    By clicking the “I accept” button below, you hereby agree to conform to altRadar’s advertising policy. Any deviations from
                    this policy, at the discretion of altRadar management, may result in the subject advertising being deleted without notice
                    or recourse.
                </span>
                <span className={classes.body}>
                    The following ad categories are explicitly banned from altRadar, academic aids, adult content, sexual content and
                    pornography. Also forbidden are automated ad-clicking links, bulk marketing, copyrighted works, counterfeits of
                    designer goods, all data entry affiliate programs, and content featuring endangered species and miracle cures.
                    Ads soliciting prostitution, escort services and adult dating websites are also rejected. Ads for products related to
                    possible illegal activities, such as radar jammers, license plate covers, signal changers, illegal drugs, weapons,
                    anabolic steroids and aids to help someone pass drug tests are prohibited.
                </span >
            </div>

            <form action="" className={classes.checkboxform}>
                <input type="checkbox" name="accept" id="accept" className={classes.checkbox} onChange={() => setChecked(!checked)} />
                <label htmlFor="accept">I Agree to all the Advertisement policies by altRadar</label>
            </form>

            <Button
                className={'cardButton active'}
                label={'Start Posting Advertisement'}
                handleClick={handleClick}
            />
        </>
    )
}

export default NewAdPrompt;