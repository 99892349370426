import { createReducer } from '@reduxjs/toolkit';
import {
    setJWT,
    setAccessToken,
    setRefreshToken,
    setExpiryToken,
    setClearToken
} from './actions';

const initialState = {
    jwt: '',
    access_token: '',
    refresh_token: '',
    expiry_token: '',
}

export default createReducer(initialState, (builder) => {
    builder
        .addCase(setJWT, (state, { payload: { jwt } }) => {
            state.jwt = jwt;
        })
        .addCase(setAccessToken, (state, { payload: { access_token } }) => {
            state.access_token = access_token;
        })
        .addCase(setRefreshToken, (state, { payload: { refresh_token } }) => {
            state.refresh_token = refresh_token;
        })
        .addCase(setExpiryToken, (state, { payload: { expiry_token } }) => {
            state.expiry_token = expiry_token;
        })
        .addCase(setClearToken, (state) => {
            state.jwt = '';
            state.access_token = '';
            state.refresh_token = '';
            state.expiry_token = '';
            localStorage.clear();
        })
})
