import { circleCookieApi } from "../serverApi";

const CircleCookie = async (token) => {
    try {
        const payload = {
            token: token,
        }
        const result = await circleCookieApi(payload);
        return result;
    } catch (error) {
        // CustomToast(error.message, 'error');
        console.log('error', error);
    }
}

export default CircleCookie;