import React, { useEffect } from 'react';
// import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { store } from '../../store/redux';

const SSOAuthorization = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        // const state = queryParams.get('state');
        // const client_id = queryParams.get('client_id');
        const redirect_uri = queryParams.get('redirect_uri');
        // const response_type = queryParams.get('response_type');
        // const scope = queryParams.get('scope');
        // const jwtToken = store.getState()._session.access_token;
        const userId = store.getState()._static.id;
        // console.log('state', state, 'client_id', client_id, 'redirect_uri', redirect_uri);

        if (redirect_uri) {
            const redirectUri = `${redirect_uri}?&code=${userId}`; // Redirect upon successful authorization.
            console.log('redirectUri ----- ', redirectUri);
            window.location.href = redirectUri;
        }
    }, [location.search]);

    return <div>Authorizing...</div>;
};

export default SSOAuthorization;
