const ROUTES = [
  {
    path: 'public',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: '/profile/edit',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'link',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'my-videos',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'earnings',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'demographics',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'subscription',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'settings',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'settings/account',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'watch-later',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'advertisement',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'radar',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'charts',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'search',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'profile',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'all-photos',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'image-crop',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'video-player',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'public',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'main-profile',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'upload',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'edit-video',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'publicProfile',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'photo-details',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: '/video/edit',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'play',
    role: ['ARTIST', 'BAND']
  },
  {
    path: '/videoPlayPage',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: '/video/live-streaming',
    role: ['ARTIST', 'BAND']
  },
  {
    path: '/video/joinstream',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: '/payment/checkout',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'fan-code',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'leaderboard',
    role: ['ARTIST', 'BAND']
  },
  {
    path: 'notifications',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'messages',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'my-playlist',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'all-follower',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'all-following',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'gcp-video',
    role: ['ARTIST']
  },
  {
    path: 'gcp-youtube-poc',
    role: ['ARTIST']
  },
  {
    path: 'gcp-go-live-poc',
    role: ['ARTIST']
  },
  {
    path: 'all-videos',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'circle-space',
    role: ['ARTIST']
  },
  {
    path: 'auth-circle',
    role: ['ARTIST', 'BAND', 'USER']
  },
  {
    path: 'apple-musickit-poc',
    role: ['ARTIST']
  }
];

export default ROUTES;
