import React from 'react';
import { Helmet } from 'react-helmet';

const CircleWidget = () => {
  return (
    <Helmet>
      <script>
        {`
          (function (w,d,s,o,f,js,fjs) {
            w['circleWidget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
          }(window, document, 'script', 'mw', 'https://backstage-pass-9a7a26.circle.so/external/widget.js'));

          mw('init', {
            community_public_uid: 'de000722',
            brand_color_dark: '#ef4042',
            brand_color_light: '#ef4042',
            default_appearance: 'light'
          });

          // Optional: pass in a default space slug and/or post slug
          // mw('setDefaults', {
          //   space_slug: '',
          //   post_slug: ''
          // });
        `}
      </script>
    </Helmet>
  );
};

export default CircleWidget;
