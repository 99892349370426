const SidebarBottomContent = [
    'Alternative',
    'Blues',
    'Indie',
    'Pop',
    'Rock',
    'Rap',
    'R & B',
    'techno',
]

export default SidebarBottomContent;