import { useQuery, gql } from '@apollo/client';

const useQueryGraphql = (query, variables) => {
    const defaultQuery = gql`query { __typename }`; // No-op query

    const Query = query ? gql`${query}` : defaultQuery;
    const { loading, error, data, refetch } = useQuery(Query, {
        variables: query ? variables : {},
        notifyOnNetworkStatusChange: true,
    });

    return { loading, error, data, refetch };
};

export default useQueryGraphql;
