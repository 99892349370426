import axios from 'axios';
import { setClearToken } from '../../store/redux/token/actions';
import { store } from '../../store/redux';
import CustomToast from '../../utils/CustomToast';

const ApiCaller = async (
    endpoint,
    method,
    body
) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://18.204.254.128:8070';
    const jwt = store.getState()._session.access_token;

    const headers = {};
    if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`;
    }

    const config = {
        method: method,
        url: `${baseUrl}${endpoint}`,
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        data: body
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (error.response || error.response.status === 400) {
            CustomToast(error.response.data.message);
        }
        else if (error.response && error.response.status === 401) {
            store.dispatch(setClearToken());
            window.location.reload();
        }
        else if (error.response && error.response.status === 404) {
            CustomToast(error.response.data.message);
        }
        else {
            if (window.navigator.onLine) {
                CustomToast('Server Down!', 'error');
            } else {
                CustomToast('No internet connection!', 'error');
            }
        }
        throw error; // rethrow the error to maintain error propagation
    }
};

export default ApiCaller;
