import CustomToast from '../../../utils/CustomToast';
import { addWatchLater } from "./serverApi";

export const AddWatchLater = async ({ videoId, videoName, videoThumbnail }) => {
    try {
        const data = {
            "videoId": videoId,
            "videoName": videoName,
            "videoThumbnail": videoThumbnail
        }
        const result = await addWatchLater(data);
        if (result.status === 200) CustomToast('Saved to Watch later', 'success');
        return result;
    } catch (error) {
        CustomToast('Unable to add to watchlater', 'error');
    }
}

export default AddWatchLater;
