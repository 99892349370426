import Footer from '../../../components/footerSection';
import Footer2 from '../../../components/footer2Section';

const FooterSection = () => {
    return (
        <div>
            <Footer />
            <Footer2 />
        </div>
    )
}

export default FooterSection